<template>
  <div class="onboarding w-100">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <OnboardingCarousel @page-change="updateCurrentSlide" />
          </div>
          <p class="skip-onboard-link" @click="skipOnboarding">
            <router-link to="/"> Skip </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OnboardingCarousel from "../components/OnboardingCarousel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
export default {
  name: "Onboarding",
  analytics: null,
  auth: null,
  components: {
    OnboardingCarousel: OnboardingCarousel,
  },
  data() {
    return {
      onboardingPage: 0,
    };
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
    logEvent(this.$options.analytics, "onboarding_started");
  },
  methods: {
    skipOnboarding() {
      logEvent(this.$options.analytics, "onboarding_completed", {
        current_page: this.onboardingPage + 1,
      });
      window.localStorage.setItem(
        "onboarded" + this.$options.auth.currentUser.uid,
        true
      );
    },
    updateCurrentSlide(slide) {
      this.onboardingPage = slide;
    },
  },
};
</script>

<style lang="scss">
#app {
  .body-container {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}
</style>
