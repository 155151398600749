<template>
  <div class="parcel-information">
    <div ref="parcelDetails" class="parcel-details parcelDetailsWrapper">
      <div class="details-contents">
        <div v-if="parcel" class="parcelHoverDetails_wrapper">
          <div
            v-if="parcelDetailsExpanded"
            class="sr_parcelDetails_row prev_next_parcel_row"
          >
            <button
              id="back-to-map"
              class="btn blue"
              @click="$emit('close-details')"
            >
              Close Selected Parcel Info
            </button>
          </div>
          <div class="sr_parcelDetails_row fixed_owner_row text-left">
            <p>
              <strong>Owner</strong><br />
              {{ parcel["owner"] }}
            </p>
            <p>
              <strong>Address</strong><br />
              {{ parcel["address"] }}
            </p>
            <p>
              <strong>Parcel ID</strong><br />
              {{ parcel["parcel_id"] }}
            </p>
          </div>
          <div class="sr_parcelDetails_row main_parcel_details text-left">
            <div v-if="parcelDetailsExpanded && detailsOrientation !== 'full'">
              <div v-html="expandedDetails" />
            </div>
          </div>
          <div
            v-if="parcelDetailsExpanded && detailsOrientation !== 'full'"
            class="sr_parcelDetails_row county_link_row"
          >
            <p @click="logCountyClick">
              <a
                :href="
                  'https://reportallusa.com/cama_redir?county_id=' +
                  parcel['county_id'] +
                  '&rausa_id=' +
                  parcel['load_table_gid'] +
                  '&parcel_id=' +
                  parcel['parcel_id']
                "
                class="btn green"
                target="_blank"
                >County Link</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="parcelDetailsExpanded && detailsOrientation === 'full'"
      ref="modalDetails"
      class="modal-details"
    >
      <div v-if="parcelDetailsExpanded" class="close-details">
        <span style="cursor: pointer" @click="$emit('close-details')"
          >&times;</span
        >
      </div>
      <div
        v-if="parcelDetailsExpanded && !detailsLoaded"
        class="lds-dual-ring"
      />
      <div v-html="expandedDetails" />
    </div>
    <div
      v-if="parcelDetailsExpanded"
      class="overlay-mask"
      @click="$emit('close-details')"
    />
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import authHeader from "../store/services/auth-header";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
export default {
  name: "ParcelDetails",
  analytics: null,
  props: {
    parcel: {
      type: Object,
      default: null,
    },
    parcelDetailsExpanded: Boolean,
    clickedParcel: {
      type: Object,
      default: null,
    },
    detailsOrientation: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      expandedDetails: null,
      lastExpandedParcelId: "",
      detailsLoaded: false,
    };
  },
  computed: {
    footerPosition() {
      return {
        "details-left": this.detailsOrientation === "left",
        "details-right": this.detailsOrientation === "right",
        "details-center": this.detailsOrientation === "center",
        "details-full": this.detailsOrientation === "full",
      };
    },
  },
  watch: {
    clickedParcel(parcel) {
      if (this.parcelDetailsExpanded) {
        $(".parcel-information").show();
        $(".sr_parcelDetails").hide();
        $(".searchResults").hide();
        $(".search .searchBox").val("");
        if (
          this.expandedDetails !== null ||
          parcel.properties["robust_id"] !== this.lastExpandedParcelId
        ) {
          this.expandedDetails = null;
          this.loadDetails();
          this.lastExpandedParcelId = parcel.properties["robust_id"];
        }
      }
    },
    parcelDetailsExpanded(expanded) {
      if (!(expanded && this.detailsOrientation !== "full")) {
        this.detailsLoaded = false;
      }
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
  },
  methods: {
    loadDetails() {
      if (this.parcel === null) {
        return;
      }
      this.detailsLoaded = false;
      authHeader().then((headers) => {
        axios
          .get(
            process.env.VUE_APP_TILE_BASE_URL +
              "/details/v1/" +
              this.parcel["robust_id"],
            {
              headers: headers,
            }
          )
          .then((response) => {
            const attributes = document.createElement("div");
            attributes.innerHTML = response.data;
            attributes.style.cssText =
              "position:fixed; top:-9999px; opacity:0;";
            document.body.appendChild(attributes);
            const detailHeight = attributes.clientHeight;
            const detailWidth = attributes.clientWidth;
            if (this.detailsOrientation === "full") {
              this.$refs.modalDetails.style.top =
                (window.innerHeight - detailHeight) / 2 + "px";
              this.$refs.modalDetails.style.left =
                (window.innerWidth - detailWidth) / 2 + "px";
              this.$refs.modalDetails.style.width = detailWidth + "px";
              this.$refs.modalDetails.style.height = detailHeight + "px";
            }
            attributes.parentNode.removeChild(attributes);
            this.expandedDetails = response.data;
            this.detailsLoaded = true;
          });
      });
    },

    logCountyClick() {
      logEvent(this.$options.analytics, "county_link");
    },
  },
};
</script>

<style scoped></style>
