<template>
  <div class="my-account w-100 text-left align-self-start">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-start
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="body-content auth">
            <h1>My Account</h1>
            <form @submit.prevent="submit()">
              <div class="form-fields text-left mt-4 mb-4">
                <div class="profile-image acct-section">
                  <img
                    v-if="photoUrl"
                    :src="photoUrl"
                    class="img-fluid"
                    :alt="displayName"
                    width="100"
                  />
                  <img
                    v-else
                    src="../assets/placeholder_profile.png"
                    class="img-fluid"
                    alt="Default Image"
                    width="100"
                  />
                  <!--<p><button type="button" class="btn" @click="chooseFiles">Change Profile Picture</button><input type="file" ref="file" class="d-none" id="profile_upload" @change="uploadImage"></p>-->
                </div>
                <div class="name-saved acct-section">
                  <label for="username"><strong>Login Information</strong></label>
                  <input
                    v-show="editField == 'name'"
                    id="username"
                    v-model="editName"
                    :placeholder="displayName"
                    :class="{inputDisplay : (editField == 'name')}"
                    class="field-value form-control w-50"
                    type="text"
                  />
                  <span
                    v-if="displayName === null && editField == 'name'"
                    type="text"
                    class="readonly-field"
                  >
                    No Name Provided
                  </span>
                  <span
                    v-else-if="editField !== 'name'"
                    type="text"
                    class="readonly-field"
                  >
                    {{ displayName }}
                  </span>
                  <span
                    :class="editField !== 'name'?'edit-background':'save-background'"
                  >
                    <img
                      v-if="editField !== 'name'"
                      class="edit-button"
                      src="../assets/edit.svg"
                      alt="edit icon"
                      aria-hidden="true"
                      @click="updateField('name')"
                    />
                    <img
                      v-else
                      src="../assets/save.svg"
                      alt="save icon"
                      aria-hidden="true"
                      class="img-fluid edit button"
                      @click="updateField('name')"
                    />
                  </span>
                  <input
                    v-if="displayEmail === null"
                    id="email"
                    type="text"
                    value="No Email Provided"
                    readonly
                    class="readonly-field"
                  />
                  <input
                    v-else
                    id="email"
                    type="text"
                    :value="displayEmail"
                    readonly
                    class="readonly-field"
                  />
                </div>
                <div class="acct-type acct-section">
                  <label><strong>Account Type</strong></label>
                  <p
                    v-if="activeSubPretty === ''"
                    id="accountTypeStored"
                  >
                    Account Information Unavailable
                  </p>
                  <p
                    v-else
                    id="accountTypeStored"
                  >
                    {{ subscriptionPeriodPretty }} {{ activeSubPretty }} is {{ activeStatus }} and {{ expiresOrRenews }}
                    {{ expirationDatePretty }}
                  </p>
                  <span
                    v-if="activeSub == 'desktop_subscription'"
                  >
                    <p>
                      <a
                        v-if="activeStatus != 'cancelled'"
                        v-b-modal="'modalChangeSubscription'"
                        class="btn red rounded"
                        @click="changeModal('cancel')"
                      >Cancel Subscription</a>
                    </p>
                  </span>
                </div>
                <div
                  v-if="desktopSubscriber"
                  class="payment-method acct-section"
                >
                  <label><strong>Payment Method</strong></label>
                  <p id="paymentStored">
                    {{ paymentInformation.brand }} ending
                    {{ paymentInformation.last4 }}
                  </p>
                  <p>
                    <BButton
                      v-b-modal="'modalEditPayment2'"
                      class="btn"
                    >
                      Edit Payment Method
                    </BButton>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <BModal
        :id="'modalEditPayment2'"
        class="payment-modal"
        hide-footer
      >
        <Subscribe update-subscription />
      </BModal>
      <BModal
        :id="'modalChangeSubscription'"
        ref="modalChangeSubscription"
        class="payment-modal"
        hide-footer
        hide-header
      >
        <div v-if="changeType == 'cancel'">
          <p> Are you sure you want to cancel your subscription?</p>
          <span style="float:right;">
            <a
              class="btn red rounded"
              @click="hideModal"
            >No</a>&nbsp;
            <a
              class="btn green rounded"
              @click="cancelSubscription"
            >
              Yes
            </a>
          </span>
        </div>
      </BModal>
      <BModal
        :id="'modalChangeSubscriptionFinish'"
        ref="modalChangeSubscriptionFinish"
        class="payment-modal"
        hide-footer
        hide-header
      >
        <div
          v-if="changeSuccess == true"
        >
          <p v-if="changeType == 'cancel'"> You have successfully cancelled your subscription.</p>
          <span style="float:right;">
            <a
              class="btn green rounded"
              @click="reloadPage"
            >
              Close
            </a>
          </span>
        </div>
        <div
          v-else
        >
          <p> There was an error processing your request. Please try again. If the error persists, please reach out to our customer support team at support@landglide.com.</p>
          <span style="float:right;">
            <a
              class="btn green rounded"
              @click="hideModal"
            >
              Close
            </a>
          </span>
        </div>
      </BModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import authHeader from "../store/services/auth-header";
import Subscribe from "../components/Subscribe";
import { getAuth, updateProfile } from "firebase/auth";
import { BButton, BModal, VBModal } from "bootstrap-vue";
export default {
  name: "MyAccount",
  components: {
    Subscribe,
    BButton,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      selectedFile: null,
      paymentInformation: {
        brand: "",
        last4: "",
      },
      editField: "",
      editName: "",
      changeType: "",
      changeSuccess: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      loggedIn: "loggedIn",
      activeSub: "activeSub",
      active: "active",
      expired: "expired",
      auth: "auth",
      expirationDate: "expirationDate",
    }),
    activeStatus() {
      return this.auth[this.activeSub]["is_autorenewing"] || ((this.activeSub == "free_trial") && this.trialActive)
        ? "active"
        : "cancelled";
    },
    expiresOrRenews() {
      return this.auth[this.activeSub]["is_autorenewing"] && ( this.activeSub !== "free_trial" )
        ? "renews"
        : "expires";
    },
    activeSubPretty() {
      if (!this.activeSub) {
        return "";
      }
      return this.activeSub.replace(/_/g, " ");
    },
    expirationDatePretty() {
      if (!this.expirationDate) {
        return "";
      }
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return this.expirationDate.toLocaleDateString("en-US", options);
    },
    subscriptionPeriodPretty() {
      if (!this.auth[this.activeSub].payment_period) {
        return "";
      }
      return this.auth[this.activeSub].payment_period.replace(/_/g, " ");
    },
    desktopSubscriber() {
      return (
        {}.hasOwnProperty.call(this.auth, "desktop_subscription") &&
        this.auth.desktop_subscription["is_active"]
      );
    },
    photoUrl() {
      if (this.user && this.user.data) {
        return this.user.data.photoUrl;
      }
      return null;
    },
    displayName() {
      if (this.user && this.user.data) {
        return this.user.data.displayName;
      }
      return null;
    },
    displayEmail() {
      return this.user.data.email;
    },
    trialActive() {
      if (this.activeSub !== "free_trial") {
        return false;
      } else {
        // Date.now returns in milliseconds while expiration date is in seconds
        return this.auth[this.activeSub]["expiration_date"] > (Date.now()/1000);
      }
    },
  },

  mounted() {
    if (this.desktopSubscriber) {
      authHeader().then((headers) => {
        axios
          .get(
            process.env.VUE_APP_SERVICE_BASE_URL +
              "/rest/landglide/firebase/v1/payment-info",
            {
              headers: headers,
            },
          )
          .then((response) => {
            this.paymentInformation = response.data;
          });
      });
    }
    this.editName = this.displayName;
  },

  methods: {
    chooseFiles() {
      document.getElementById("profile_upload").click();
    },
    uploadImage(event) {
      // UPLOAD IMAGE CODE WILL GO HERE
      this.selectedFile = event.target.files[0];

      // Will probably need to use a cdn or something to store these custom profile pictures so will need to be handled in integration
      alert(
        "Placeholder Function for uploading image - will need a server or CDN to store custom profile pictures",
      );
    },
    submit() {
      // submit form function to save data here
      console.log("submit");

      // Below is what generally should be used for image upload to a server/CDN
      // const formData = new FormData();
      // formData.append('myFile', this.selectedFile, this.selectedFile.name);
      // axios.post('my-domain.com/file-upload', formData);
    },
    updateField(name) {
      const auth = getAuth();
      if (this.editField == name) {
        switch (name) {
        case "name":
          updateProfile(auth.currentUser, {
            displayName: this.editName, photoURL: this.user.data.photoUrl,
          }).then(() => {
            // Profile updated!
            this.user.data.displayName = this.editName;
          }).catch((error) => {
            // An error occurred
            // console.log(error);
          });
          break;
        }
        // this.editName
        this.editField = "";
      } else {
        this.editField = name;
      }
    },
    changeModal(value) {
      this.changeType = value;
    },
    hideModal() {
      this.$refs["modalChangeSubscription"].hide();
      this.$refs["modalChangeSubscriptionFinish"].hide();
    },
    reloadPage() {
      window.location.reload();
    },
    async cancelSubscription() {
      const header = await authHeader();
      try {
        const response = await axios.post(
          process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/firebase/v1/unsubscribe",
          { },
          { headers: header },
        );

        if (response.status == 204) {
          this.changeSuccess = true;
        }
      } catch (exception) {
        this.changeSuccess = false;
      }
      this.$refs["modalChangeSubscription"].hide();
      this.$refs["modalChangeSubscriptionFinish"].show();
    },
  },
};
</script>
<style lang="scss">
.acct-section {
  font-size: 1.15rem;
}
.inputDisplay {
  display: inline !important;
}
.edit-button {
  filter: invert(1);
  height: 20px;
  width: 25px;
}
.edit-background {
  background: #61B39E;
  border-radius: 125%;
  display:inline-block;
  width: 25px;
  height: 25px;
}
.save-background {
  display:inline-block;
  width: 25px;
  height: 25px;
}
</style>

