<template>
  <div class="terms">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-start
        body-container
      "
    >
      <div>
        <div
          class="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4 text-center"
        >
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
        </div>
        <h1>Terms Of Service</h1>
        <div class="terms-content text-left">
          <h3>Purpose</h3>
          <p>
            Real Estate Portal USA LLC's mobile app LandGlide ("App") is a real
            estate service that allows individual user interaction including,
            but not limited to search, and data overlays on to other mapping
            services. The LandGlide Terms of Service is the set of user policies
            that govern the usage of the App. Information on the functions of
            the App and how to use them is available at
            <a
              href="https://reportallusa.com/solutions/landglide"
              target="_blank"
              >https://reportallusa.com/solutions/landglide</a
            >.
          </p>

          <p>
            By using the App, and the remote services to which it connects to
            access data ("Services"), you acknowledge that you have read,
            understood, and agree to be bound by the following terms of service
            and any future modifications to this agreement (collectively, the
            "Terms"). If at any time you do not agree to all the Terms, please
            immediately terminate your use of the App and its Services. You, on
            your own behalf and on behalf of your company, acknowledge that
            these Terms are binding and enforceable on you and your company
            (collectively, "You" or "Subscriber").
          </p>

          <h3>Usage</h3>
          <p>
            You are permitted to use the App and its Services for both
            commercial and non-commercial purposes. You are not permitted to
            distribute, sell, or resell any of the Data that is provided through
            the App or its Services. You must adhere to all policies posted
            within the Service Terms and all accompanying documentation. By
            using the App and its Services, you are subject to agreement and
            compliance with the Service Terms. All such policies are hereby
            incorporated by reference into these Terms.
          </p>

          <h3>Service Terms</h3>
          <p>
            As a condition of your use of the App, you warrant to Real Estate
            Portal USA LLC that you will not use the App, nor its Services, nor
            the Data for any purpose that is unlawful or prohibited by these
            terms, conditions, or notices.
          </p>

          <p>
            As a condition of your use of the App and its Services, you may not:
          </p>

          <ol>
            <li>
              Use the App or its Services in any manner which could damage,
              disable, overburden, or impair the App or its Services, or
              interfere with any other party's use and enjoyment of the App or
              its Services.
            </li>
            <li>
              Interfere with or disrupt, or attempt to interfere with or
              disrupt, the App or Services or networks connected to the App, or
              disobey any requirements, procedures, policies or regulations of
              networks connected to the App or Services.
            </li>
            <li>
              Issue or attempt to issue excessive requests to the App or its
              Services.
            </li>
            <li>
              Issue or attempt to issue any Denial of Service attack against the
              App or its Services.
            </li>
            <li>
              Access or attempt to access any other user's account, or
              misrepresent or attempt to misrepresent your identity while using
              the App or its Services.
            </li>
            <li>
              Interfere with or attempt to interfere with the App or its
              Services or access them using a method other than the interface
              and the instructions we provide.
            </li>
            <li>
              Use any automated means to access the App or its Services,
              including without limitation robots, spiders, scripts, or
              information-scraping tools.
            </li>
            <li>
              Allow or attempt to allow multiple users to access a single user's
              credentials, account, or session.
            </li>
            <li>
              Bypass or attempt to bypass any access or usage restrictions of
              the App or its Services.
            </li>
            <li>
              Reproduce, duplicate, copy, sell, resell, distribute,
              reverse-engineer, disassemble, or exploit any portion of the App
              or its Services or Data, other than as expressly allowed under
              these Terms of Use.
            </li>
            <li>
              Use Real Estate Portal USA's or any Participating Institution's
              name, trademarks including LandGlide, or other material in
              connection with them, or to transmit, any unsolicited
              communications or emails.
            </li>
            <li>
              Your access to the App and its Services may be suspended or
              terminated if you do not comply with our terms or policies or if
              we are investigating suspected misconduct.
            </li>
          </ol>

          <h3>Accuracy of Information</h3>
          <ol>
            <li>
              The data furnished through and compiled by Real Estate Portal USA,
              LLC is believed to be reliable. However, the accuracy,
              completeness, timeliness, or correctness of such data is not
              guaranteed. To Real Estate Portal USA's knowledge, user's use of
              such data within the scope of this license does not infringe upon
              or otherwise violate the copyright or other intellectual property
              of the persons, entities, or organizations from whom such data has
              been obtained or derived.
            </li>
            <li>
              REAL ESTATE PORTAL USA, LLC IS FURNISHING THE PARCEL DATA ON AN
              "AS IS" BASIS WITHOUT ANY SUPPORT WHATSOEVER AND WITHOUT
              REPRESENTATION OR WARRANTY, INCLUDING, BUT NOT IN ANY MANNER
              LIMITED TO, FITNESS, MERCHANTABILITY, AND NON-INFRINGEMENT, WHICH
              WARRANTIES ARE HEREBY EXPRESSLY DISCLAIMED.
            </li>
            <li>
              The parcel data is neither a legally recorded map nor a survey,
              and is not intended to be used as one. The parcel data is a
              compilation of records, information, and data from various city,
              county, regional, state, and federal offices and other sources and
              should be used for reference only. No representation is made that
              the features presented accurately reflect true location. Real
              Estate Portal USA and any other person, entity, or organization
              from which such data was obtained or derived assume no liability
              for any errors or omissions herein.
            </li>
          </ol>

          <h3>Copyright and Proprietary Information</h3>
          <p>
            All other materials and matter displayed or performed in the App or
            its Services, including, but not limited to text, graphics, maps,
            logos, tools, photographs, images, illustrations, software or source
            code, audio and video, and animations are the property of Real
            Estate Portal USA LLC and/or third parties and are protected by
            United States and international copyright laws.
          </p>

          <h3>Account Cancellation or Suspension</h3>
          <p>
            Real Estate Portal USA LLC may cancel or suspend your access to the
            App and/or its Services for any reason. Upon cancellation or
            suspension, your right to use the service will stop immediately.
          </p>

          <p>
            At the end of each subscription duration, the subscription will
            automatically renew unless cancelled at least 24-hours before the
            end of the current subscription duration. You may cancel at any time
            through your iTunes account or Google Play Account. As soon as you
            cancel the service your right to use it stops at the end of the
            current subscription duration.
          </p>

          <h3>About These Terms</h3>
          <p>
            Real Estate Portal USA LLC reserves the right, at its sole
            discretion, to modify, add, or delete portions of or otherwise
            change these Terms at any time without notice to you by posting the
            changed Terms on the Site. All changes shall be effective
            immediately upon posting. Please check these Terms periodically for
            changes. Your continued use of the Site and Services after the
            posting of changes constitutes your binding acceptance of such
            changes.
          </p>

          <p>
            If any term in this agreement is not enforceable for any reason,
            other terms will not be affected.
          </p>

          <p>Version 1.0 Last updated May 2017</p>

          <p>© 2020 Real Estate Portal USA, LLC. U.S. Patent No. 8,255,418.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
export default {
  name: "TermsOfService",
  mounted() {
    const firebaseApp = getApp();
    const analytics = getAnalytics(firebaseApp);
    logEvent(analytics, "view_terms_of_service");
  },
};
</script>

<style lang="scss">
.terms {
  ol {
    list-style-type: lower-alpha;
    font-size: 1.15rem;
    line-height: 1.5em;
  }
}
</style>
