<template>
  <div class="map-view">
    <div
      class="
        container-fluid
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-6 col-md-5 col-lg-4">
          <div class="parcel-details w-100 h-100">
            <Search
              class="search-form"
              :results-hidden="resultsHidden"
              :selected-map="selectedMap"
              :autocomplete-cleared="autocompleteCleared"
              @hideResults="resultsHidden = true"
              @show-results="resultsHidden = false"
              @clear-autocomplete="autocompleteCleared = false"
            />
            <ParcelDetails
              :parcel="parcelDetails"
              :parcel-details-expanded="parcelDetailsExpanded"
              :clicked-parcel="clickedParcel"
              :details-orientation="detailsOrientation"
              @close-details="removeSelected"
            />
          </div>
        </div>
        <div class="col-6 col-md-7 col-lg-8">
          <MainMap
            v-if="selectedMap === 'mapbox'"
            ref="mapComp"
            :user-location="userLocation"
            :parcel-details-expanded="parcelDetailsExpanded"
            @hovering="parcelHovering"
            @parcelClicked="parcelClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMap from "../components/MainMap";
import Search from "../components/Search";
import ParcelDetails from "../components/ParcelDetails";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
export default {
  name: "MapView",
  analytics: null,
  components: {
    MainMap,
    Search,
    ParcelDetails,
  },
  data() {
    // 2021-01-17: Remove 'map: null'. As per soal vue-mapbox (and Vue) docs, should not make non-plain
    // objects (especially those with their own existing getter & setter assignment) reactive.
    return {
      parcelDetails: null,
      parcelDetailsExpanded: false,
      clickedParcel: null,
      showMenu: false,
      detailsOrientation: "left",
      resultsHidden: true,
      selectedMap: "mapbox",
      userLocation: null,
      containerClass: "container-fluid",
    };
  },
  created() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.userLocation = new Promise((resolve, reject) => {
      logEvent(this.$options.analytics, "requesting_location_permission");
      if (navigator.geolocation) {
        logEvent(this.$options.analytics, "allowed_location_permission");
        this.userLocation = navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
        );
      } else {
        logEvent(this.$options.analytics, "skipped_location_permission");
        reject(new Error("Geolocation permission rejected"));
      }
    });
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
  },
  methods: {
    parcelHovering(parcel) {
      this.autocompleteCleared = false;
      if (!this.parcelDetailsExpanded) {
        this.parcelDetails = parcel;
      }
    },
    parcelClicked(parcel) {
      this.autocompleteCleared = true;
      this.parcelDetailsExpanded = true;
      this.$root.$emit("clicked_parcel", parcel);
      this.clickedParcel = parcel;
    },
    orientationChanged(orientation) {
      this.autocompleteCleared = false;
      this.detailsOrientation = orientation;
    },
    removeSelected() {
      this.autocompleteCleared = false;
      this.parcelDetailsExpanded = false;
      this.$refs.mapComp.deactivateParcels();
    },
  },
};
</script>

<style lang="scss">
.mapboxgl-canvas {
  outline: none;
}
#app {
  .map-view {
    .body-container {
      min-height: calc(100vh - 83px);
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
