export const override = {
  state: {
    overrides: {
      authOverride: false,
      subscriptionOverride: false,
      expirationOverride: false,
    },
  },
  getters: {
    authOverride(state) {
      return state.overrides.authOverride;
    },
    subscriptionOverride(state) {
      return state.overrides.subscriptionOverride;
    },
    expirationOverride(state) {
      return state.overrides.expirationOverride;
    },
  },
  actions: {
    overrideAuthentication({ commit }, value) {
      commit("SET_AUTH_OVERRIDE", value);
    },
    overrideSubscription({ commit }, value) {
      commit("SET_SUBSCRIPTION_OVERRIDE", value);
    },
    overrideExpiration({ commit }, value) {
      commit("SET_EXPIRATION_OVERRIDE", value);
    },
  },
  mutations: {
    SET_AUTH_OVERRIDE(state, value) {
      state.overrides.authOverride = value;
    },
    SET_SUBSCRIPTION_OVERRIDE(state, value) {
      state.overrides.subscriptionOverride = value;
    },
    SET_EXPIRATION_OVERRIDE(state, value) {
      state.overrides.expirationOverride = value;
    },
  },
};
