<template>
  <div class="sign-in w-100">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div v-if="!storedEmail" class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <h1>Confirm your Email</h1>
            <hr />
            <h4>Please confirm your email address to continue.</h4>
          </div>
        </div>

        <div class="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
          <div class="body-content">
            <div class="form-fields text-left mt-4 mb-4">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="email"
                type="email"
                class="email-field"
              />
              <div class="error-message text-center mt-3" />
            </div>
            <p class="mt-5">
              <button class="btn blue next-btn" @click="emailConfirm">
                Confirm<font-awesome-icon :icon="['fas', 'chevron-right']" />
              </button>
            </p>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <h1>Signing In</h1>
            <div class="error-message text-center mt-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {
  getAuth,
  signInWithEmailLink,
  isSignInWithEmailLink,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
import { mapGetters } from "vuex";
export default {
  name: "SignInWithEmail",
  analytics: null,
  auth: null,
  data() {
    return {
      email: "",
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "loggedIn",
    }),
    storedEmail() {
      return window.localStorage.getItem("emailForSignIn");
    },
  },
  watch: {
    loggedIn(newState) {
      const onboarded = window.localStorage.getItem(
        "onboarded" + this.$options.auth.currentUser.uid
      );
      if (newState === true && !onboarded) {
        this.$router.push("/onboarding");
      } else if (newState === true && onboarded) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
    if (this.loggedIn) {
      this.$router.push("/");
    }
    if (!isSignInWithEmailLink(this.$options.auth, window.location.href)) {
      this.$router.push("/sign-in");
    }
    if (this.storedEmail) {
      this.signInWithEmail(this.storedEmail);
    }
  },
  methods: {
    emailConfirm() {
      this.signInWithEmail(this.email);
    },
    errorMessage(code) {
      if (code == "auth/invalid-user-token") {
        return (
          "Sorry but your credentials are no longer valid. Please sign in again.  - [" +
          code +
          "]"
        );
      } else if (code == "auth/invalid-tenant-id") {
        return (
          "Sorry but your credentials are not valid. Please contact Customer Service for assistance logging in.  - [" +
          code +
          "]"
        );
      } else if (code == "auth/user-disabled") {
        return (
          "Your account has been disabled. Please contact Customer Service for assistance logging in.  - [" +
          code +
          "]"
        );
      } else if (
        code == "auth/network-request-failed" ||
        code == "auth/too-many-requests"
      ) {
        return (
          "Something went wrong. Please try again later.  - [" + code + "]"
        );
      } else {
        return (
          "Something went wrong. Please contact Customer Service for assistance logging in. - [" +
          code +
          "]"
        );
      }
    },
    signInWithEmail(email) {
      signInWithEmailLink(this.$options.auth, email, window.location.href)
        .then((result) => {
          logEvent(this.$options.analytics, "sign_in_with_email");
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          window.localStorage.removeItem("emailForSignIn");
          if (error.code === "auth/invalid-action-code") {
            this.$router.push("/continue-with-email/expired");
          } else {
            $(".error-message").html(
              "<p>" + this.errorMessage(error.code) + "</p>"
            );
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
