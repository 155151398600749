<template>
  <div id="app">
    <Navigation
      v-if="
        [
          'MapPage',
          'SignIn',
          'SignInWithEmail',
          'SignInWithEmailConfirmation',
          'SignInWithEmailExpired',
          'SignInWithEmailLink',
          'Onboarding',
          'TermsOfService',
        ].indexOf($route.name) < 0
      "
    />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer v-if="['Onboarding', 'MapView'].indexOf($route.name) < 0" />
    <!-- no-close-on-backdrop -->
    <BModal
      :id="'modalEditPayment'"
      v-model="showPaywall"
      class="payment-modal"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <Subscribe />
    </BModal>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Subscribe from "./components/Subscribe";
import { BModal } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { getApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
export default {
  name: "App",
  analytics: null,
  components: {
    Navigation: Navigation,
    Footer: Footer,
    Subscribe: Subscribe,
    BModal,
  },
  computed: {
    ...mapGetters({
      loggedIn: "loggedIn",
      authLoading: "authLoading",
      active: "active",
      accountStatus: "accountStatus",
    }),
    showPaywall() {
      const routeName = this.$route.name;
      const passPaywallRoutes = [
        "MapPage",
        "MyAccount",
        "SignIn",
        "Onboarding",
        "SignInWithEmail",
        "SignInWithEmailConfirmation",
        "SignInWithEmailExpired",
        "SignInWithEmailLink",
        "TermsOfService",
      ];
      if (this.loggedIn && !this.authLoading && !this.active) {
        if (passPaywallRoutes.indexOf(routeName) !== -1) {
          return false;
        } else {
          logEvent(this.$options.analytics, "show_paywall");
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    accountStatus(status) {
      if (status !== "") {
        setUserProperties(this.$options.analytics, {
          account_status: status,
        });
      }
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    if (this.accountStatus !== "") {
      setUserProperties(this.$options.analytics, {
        account_status: this.accountStatus,
      });
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3c3c3c;
  min-height: 100vh;

  .body-container {
    min-height: calc(100vh - 85px);
    padding-top: 4em;
    padding-bottom: 4em;
  }
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 300ms ease;
}
</style>
