<template>
  <div class="sign-in w-100">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <div class="error-message" />
            <p>
              <button
                class="
                  btn
                  icon-btn
                  btn-even
                  d-flex
                  align-items-center
                  ml-auto
                  mr-auto
                "
                @click="appleAuth"
              >
                <font-awesome-icon :icon="['fab', 'apple']" />Continue with
                Apple
              </button>
            </p>
            <p>
              <button
                class="
                  btn
                  icon-btn
                  btn-even
                  d-flex
                  align-items-center
                  ml-auto
                  mr-auto
                "
                @click="googleAuth"
              >
                <font-awesome-icon :icon="['fab', 'google']" />Continue with
                Google
              </button>
            </p>
            <p>
              <button
                class="
                  btn
                  icon-btn
                  btn-even
                  d-flex
                  align-items-center
                  ml-auto
                  mr-auto
                "
                @click="facebookAuth"
              >
                <font-awesome-icon :icon="['fab', 'facebook-f']" />Continue with
                Facebook
              </button>
            </p>
            <p>
              <strong>or</strong>
            </p>
            <p>
              <router-link
                to="/continue-with-email"
                class="
                  btn
                  icon-btn
                  btn-even
                  d-flex
                  align-items-center
                  ml-auto
                  mr-auto
                "
              >
                <font-awesome-icon :icon="['fas', 'envelope']" />Continue with
                Email
              </router-link>
            </p>
            <div
              v-if="isUserAgentAndroid"
              class="text-center alert alert-danger"
            >
              Warning: Your mobile device may not be supported by this
              browser-based version of LandGlide. We recommend using the
              <a
                href="https://play.google.com/store/apps/details?id=com.reportallusa.landglide"
                >LandGlide app from the Google Play Store</a
              >:
              <a
                href="https://play.google.com/store/apps/details?id=com.reportallusa.landglide"
              >
                <img
                  src="https://www.landglide.com/wp-content/themes/landglide/static/img/appstore-android-dark.png"
                  width="171"
                  height="52"
                />
              </a>
            </div>
            <div v-if="isUserAgentIOS" class="text-center alert alert-danger">
              Warning: Your mobile device may not be supported by this
              browser-based version of LandGlide. We recommend using the
              <a href="https://apps.apple.com/us/app/landglide/id560902465"
                >LandGlide app from the Apple App Store</a
              >:
              <a href="https://apps.apple.com/us/app/landglide/id560902465">
                <img
                  src="https://www.landglide.com/wp-content/themes/landglide/static/img/appstore-apple-dark.png"
                  width="171"
                  height="52"
                />
              </a>
            </div>
            <h4 class="mt-5">
              Free for your first seven days. $9.99/mo or $99.99/yr thereafter.
            </h4>
            <hr />
            <p>
              By continuing, you agree to our
              <router-link to="/terms" target="_blank">
                Terms of Service
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getApp } from "firebase/app";
import { mapGetters } from "vuex";
export default {
  name: "SignIn",
  auth: null,
  analytics: null,
  data() {
    return {
      isUserAgentAndroid: null,
      isUserAgentIOS: null,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "loggedIn",
      auth: "auth",
    }),
  },
  watch: {
    loggedIn(newState) {
      if (newState) {
        const onboarded = window.localStorage.getItem(
          "onboarded" + this.$options.auth.currentUser.uid
        );
        if (!onboarded) {
          this.$router.push("/onboarding");
        } else if (onboarded) {
          this.$router.push("/");
        }
      }
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.auth = getAuth(firebaseApp);
    this.$options.analytics = getAnalytics(firebaseApp);
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  created() {
    const firebaseApp = getApp();
    this.$options.auth = getAuth(firebaseApp);
    this.$options.analytics = getAnalytics(firebaseApp);
    // Ref: https://stackoverflow.com/a/21742107
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Test for 'windows phone' first because its UA also contains 'android'.
    if (/windows phone/i.test(userAgent)) {
      // Do nothing.
    } else if (/android/i.test(userAgent)) {
      this.isUserAgentAndroid = true;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // !window.MSStream used to not wrongly detect IE11.
      this.isUserAgentIOS = true;
    }
  },
  methods: {
    facebookAuth() {
      logEvent(this.$options.analytics, "tapped_sign_in_with_facebook");
      const provider = new FacebookAuthProvider();
      signInWithPopup(this.$options.auth, provider)
        .then((result) => {
          logEvent(this.$options.analytics, "sign_in_with_facebook");
          // Nothing to do with this information
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // const credential = error.credential;
          if (errorCode === "auth/popup-closed-by-user") {
            return;
          }
          // ...
          $(".error-message").html(
            "<p>" + this.errorMessage(errorCode) + "</p>"
          );
        });
    },
    googleAuth() {
      logEvent(this.$options.analytics, "tapped_sign_in_with_google");
      const provider = new GoogleAuthProvider();
      signInWithPopup(this.$options.auth, provider)
        .then((result) => {
          logEvent(this.$options.analytics, "sign_in_with_google");
          // Nothing to do with this information
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // const credential = error.credential;
          if (errorCode === "auth/popup-closed-by-user") {
            return;
          }
          // ...
          $(".error-message").html(
            "<p>" + this.errorMessage(errorCode) + "</p>"
          );
        });
    },
    appleAuth() {
      logEvent(this.$options.analytics, "tapped_sign_in_with_apple");
      const provider = new OAuthProvider("apple.com");
      signInWithPopup(this.$options.auth, provider)
        .then((result) => {
          logEvent(this.$options.analytics, "sign_in_with_apple");
          // Nothing to do with this information
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // const credential = error.credential;
          if (errorCode === "auth/popup-closed-by-user") {
            return;
          }
          // ...
          $(".error-message").html(
            "<p>" + this.errorMessage(errorCode) + "</p>"
          );
        });
    },
    errorMessage(code) {
      if (code == "auth/invalid-user-token") {
        return (
          "Sorry but your credentials are no longer valid. Please sign in again.  - [" +
          code +
          "]"
        );
      } else if (code == "auth/invalid-tenant-id") {
        return (
          "Sorry but your credentials are not valid. Please contact Customer Service for assistance logging in.  - [" +
          code +
          "]"
        );
      } else if (code == "auth/user-disabled") {
        return (
          "Your account has been disabled. Please contact Customer Service for assistance logging in.  - [" +
          code +
          "]"
        );
      } else if (
        code == "auth/network-request-failed" ||
        code == "auth/too-many-requests"
      ) {
        return (
          "Something went wrong. Please try again later.  - [" + code + "]"
        );
      } else {
        return (
          "Something went wrong. Please contact Customer Service for assistance logging in. - [" +
          code +
          "]"
        );
      }
    },
  },
};
</script>

<style lang="scss"></style>
