import AuthService from "../services/auth.service";

const storedAuth = JSON.parse(localStorage.getItem("auth"));
const initialState = storedAuth
  ? { auth: storedAuth, authLoading: false }
  : {
      auth: {
        firebase_user_id: null,
        free_trial: null,
        android_subscription: null,
        ios_subscription: null,
        corporate_subscription: null,
        desktop_subscription: null,
      },
      authLoading: false,
    };

export const auth = {
  state: initialState,
  getters: {
    auth(state) {
      return state.auth;
    },
    activeSub(state) {
      if (!state.auth.firebase_user_id) {
        return null;
      }
      return Object.keys(state.auth)
        .map((type) => {
          if (type !== "firebase_user_id" && state.auth[type]["is_active"]) {
            return {
              type: type,
              expiration_date: state.auth[type]["expiration_date"],
            };
          }
        })
        .reduce((best, current) => {
          if (typeof best !== "undefined" && typeof current !== "undefined") {
            return current["expiration_date"] >
              state.auth[best]["expiration_date"]
              ? current["type"]
              : best;
          } else if (
            typeof current !== "undefined" &&
            typeof best === "undefined"
          ) {
            return current["type"];
          } else if (typeof best !== "undefined") {
            return best;
          }
        });
    },
    expired(state, getters) {
      if (!getters.active || getters.expirationOverride) {
        return true;
      }
      return getters.expirationDate < Date.now() || getters.expirationOverride;
    },
    expirationDate(state) {
      if (!state.auth.firebase_user_id) {
        return new Date(Date.now());
      }
      const activeSubs = Object.keys(state.auth).filter(
        (auth) => auth !== "firebase_user_id" && state.auth[auth]["is_active"]
      );
      const highestExpiration = activeSubs.reduce((highest, current) => {
        return state.auth[current]["expiration_date"] > highest
          ? state.auth[current]["expiration_date"]
          : highest;
      }, 0);
      return new Date(highestExpiration * 1000);
    },
    active(state, getters) {
      if (!state.auth.firebase_user_id) {
        return false;
      }
      return (
        Object.keys(state.auth).reduce((active, current) => {
          if (current !== "firebase_user_id") {
            return active || state.auth[current]["is_active"];
          }
        }, false) && !getters.subscriptionOverride
      );
    },
    authLoading(state) {
      return state.authLoading;
    },
    accountStatus(state, getters) {
      if (getters.activeSub === "free_trial") {
        return "free_trial";
      } else if (getters.activeSub === "ios_subscription") {
        if (
          state.auth.ios_subscription["product_id"] ===
          "NationalParcelLayerMonthlySubscription"
        ) {
          return "subscribed_month";
        } else {
          return "subscribed_yearly";
        }
      } else if (getters.activeSub === "android_subscription") {
        if (state.auth.android_subscription["period"] === "landglide_monthly") {
          return "subscribed_month";
        } else {
          return "subscribed_yearly";
        }
      } else if (getters.activeSub === "corporate_subscription") {
        if (state.auth.corporate_subscription["period"] === "monthly") {
          return "subscribed_month";
        } else {
          return "subscribed_yearly";
        }
      } else if (getters.activeSub === "desktop_subscription") {
        if (state.auth.desktop_subscription["period"] === "monthly") {
          return "subscribed_month";
        } else {
          return "subscribed_yearly";
        }
      }
      return "";
    },
  },
  actions: {
    fetchAuth({ commit }) {
      commit("SET_LOADING", true);
      AuthService.authenticate().then((data) => {
        commit("SET_AUTH", data);
        commit("SET_LOADING", false);
      });
    },
    clearAuth({ commit }) {
      localStorage.removeItem("auth");
      commit("SET_AUTH", {
        firebase_user_id: null,
        free_trial: null,
        android_subscription: null,
        ios_subscription: null,
        corporate_subscription: null,
        desktop_subscription: null,
      });
    },
  },
  mutations: {
    SET_AUTH(state, value) {
      state.auth = value;
    },
    SET_LOADING(state, value) {
      state.authLoading = value;
    },
  },
};
