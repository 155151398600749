import axios from "axios";
import authHeader from "./auth-header";

class AuthService {
  async authenticate() {
    const headers = await authHeader();
    const auth = await axios.get(
      process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/firebase/v1/auth",
      { headers: headers }
    );
    localStorage.setItem("auth", JSON.stringify(auth.data));
    return auth.data;
  }
}

export default new AuthService();
