import MapPinsService from "../services/map-pins.service";

const storedState = JSON.parse(localStorage.getItem("mapPins"));
const initialState = storedState ? { mapPins: storedState } : { mapPins: [] };

export const mapPins = {
  state: initialState,
  getters: {
    mapPins(state) {
      return state.mapPins?.map((pin) => {
        return {
          id: pin.id,
          coordinates: [pin.longitude, pin.latitude],
          note: pin.note,
          created_at: pin.created_at_ts,
          color: pin.color,
          properties: {
            address: pin.address,
            city: pin.city,
            county: pin.county,
            county_id: pin.county_id,
            owner: pin.owner,
            parcel_id: pin.parcel_id,
            state: pin.state,
            zip: pin.zip,
          },
          tags: pin.tags,
          images: pin.images,
        };
      });
    },
  },
  actions: {
    loadPins({ commit }) {
      MapPinsService.getAll().then((pins) => {
        commit("SET_PINS", pins);
      });
    },
    addPin({ commit }, pinData) {
      MapPinsService.addPin(pinData).then((pin) => {
        commit("ADD_PIN", pin);
      });
    },
    updatePin({ commit }, mapPinIdAndData) {
      MapPinsService.updatePin(mapPinIdAndData).then((pin) => {
        commit("UPDATE_PIN", pin);
      });
    },
    deletePin({ commit }, pinId) {
      MapPinsService.deletePin(pinId).then(() => {
        commit("DELETE_PIN", pinId);
      });
    },
    clearPins({ commit }) {
      MapPinsService.clearPins();
      commit("SET_PINS", []);
    },
  },
  mutations: {
    SET_PINS(state, value) {
      state.mapPins = value;
    },
    ADD_PIN(state, value) {
      state.mapPins.push(value);
    },
    UPDATE_PIN(state, value) {
      const pinIndex = state.mapPins.findIndex(
        (mapPin) => value.id === mapPin.id
      );
      if (pinIndex !== -1) {
        // Here we want to set: state.mapPins[pinIndex] = value; but as per
        // https://vuejs.org/v2/guide/reactivity.html#For-Arrays direct assignment
        // is not reactive. So we use the below instead:
        state.mapPins.splice(pinIndex, 1, value);
        // Alternatively this should work: Vue.set(state.mapPins, pinIndex, value);
        // but gives 'ReferenceError: Vue is not defined'.
      } else {
        console.log(
          "map-pins.module mapPins mutation UPDATE_PIN Warning: Unexpectedly did not find map pin matching id: " +
            value.id
        );
      }
    },
    DELETE_PIN(state, id) {
      const pinIndex = state.mapPins.findIndex((mapPin) => id === mapPin.id);
      if (pinIndex !== -1) {
        state.mapPins.splice(pinIndex, 1);
      } else {
        console.log(
          "map-pins.module mapPins mutation DELETE_PIN Warning: Unexpectedly did not find map pin matching id: " +
            id
        );
      }
    },
  },
};
