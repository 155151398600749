import Vue from "vue";
import Vuex from "vuex";

import { user } from "./modules/user.module";
import { auth } from "./modules/auth.module";
import { mapPins } from "./modules/map-pins.module";
import { override } from "./modules/override.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mapBoundsArray: null, // MainMap responsible for keeping this up-to-date with latest map viewport.
  },
  mutations: {
    setMapBoundsArray(state, mapBoundsArray) {
      state.mapBoundsArray = mapBoundsArray;
    },
  },
  modules: {
    user,
    auth,
    override,
    mapPins,
  },
  // dfuhry 2020-01-18: Throw an error any time Vuex state is mutated outside of mutation handlers, to detect bugs.
  // Note Vuex docs recommend enabling this only in development for performance reasons.
  // Ref: https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});
