var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(
      [
        'MapPage',
        'SignIn',
        'SignInWithEmail',
        'SignInWithEmailConfirmation',
        'SignInWithEmailExpired',
        'SignInWithEmailLink',
        'Onboarding',
        'TermsOfService',
      ].indexOf(_vm.$route.name) < 0
    )?_c('Navigation'):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),(['Onboarding', 'MapView'].indexOf(_vm.$route.name) < 0)?_c('Footer'):_vm._e(),_c('BModal',{staticClass:"payment-modal",attrs:{"id":'modalEditPayment',"no-close-on-backdrop":"","no-close-on-esc":"","hide-header":"","hide-footer":""},model:{value:(_vm.showPaywall),callback:function ($$v) {_vm.showPaywall=$$v},expression:"showPaywall"}},[_c('Subscribe')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }