<template>
  <BCarousel
    id="carouselOnboarding"
    ref="carousel"
    v-model="currentSlide"
    indicators
    fade
    :interval="0"
    @sliding-end="updatePage"
  >
    <BCarouselSlide>
      <template #img>
        <h1>
          LandGlide gives you access to parcel data on all your mobile, tablet,
          and desktop devices.
        </h1>
        <img
          src="../assets/onboarding1.png"
          width="545"
          alt="Landglide app mockups"
          class="img-fluid mt-4 mb-4"
        />
        <p>
          <button class="btn black next-btn" @click="$refs.carousel.next()">
            Next<font-awesome-icon :icon="['fas', 'chevron-right']" />
          </button>
        </p>
      </template>
    </BCarouselSlide>
    <BCarouselSlide>
      <template #img>
        <h1>Search by address, owner,<br />or parcel number.</h1>
        <img
          src="../assets/onboarding2.png"
          width="545"
          alt="Landglide app mockups"
          class="img-fluid mt-4 mb-4"
        />
        <p>
          <button class="btn black next-btn" @click="$refs.carousel.next()">
            Next<font-awesome-icon :icon="['fas', 'chevron-right']" />
          </button>
        </p>
      </template>
    </BCarouselSlide>
    <BCarouselSlide>
      <template #img>
        <h1>Hover over a property to<br />view key parcel data.</h1>
        <img
          src="../assets/onboarding3.png"
          width="545"
          alt="Landglide app mockups"
          class="img-fluid mt-4 mb-4"
        />
        <p>
          <button class="btn black next-btn" @click="$refs.carousel.next()">
            Next<font-awesome-icon :icon="['fas', 'chevron-right']" />
          </button>
        </p>
      </template>
    </BCarouselSlide>
    <BCarouselSlide>
      <template #img>
        <h1>Right-click on the map to save<br />your favorite locations.</h1>
        <img
          src="../assets/onboarding4.png"
          width="545"
          alt="Landglide app mockups"
          class="img-fluid mt-4 mb-4"
        />
        <p @click="onboardingComplete">
          <!-- Point this router link to the homepage for prod as that will be the new link for the map/search view -->
          <router-link to="/" class="btn black next-btn">
            Next<font-awesome-icon :icon="['fas', 'chevron-right']" />
          </router-link>
        </p>
      </template>
    </BCarouselSlide>
  </BCarousel>
</template>
<script>
import "../scss/custom_styles/_onboarding_carousel.scss";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
export default {
  name: "OnboardingCarousel",
  analytics: null,
  auth: null,
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
  },
  methods: {
    onboardingComplete() {
      logEvent(this.$options.analytics, "onboarding_completed", {
        current_page: this.currentSlide + 1,
      });
      window.localStorage.setItem(
        "onboarded" + this.$options.auth.currentUser.uid,
        true
      );
    },
    updatePage() {
      this.$emit("page-change", this.currentSlide);
    },
  },
};
</script>

<style lang="scss"></style>
