<template>
  <div class="sign-in w-100">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <img
              src="../assets/alert_icon.svg"
              alt="email icon"
              aria-hidden="true"
              class="img-fluid mt-5 mb-3"
            />
            <h1>Uh oh!</h1>
            <hr />
            <h4>It looks like that link is expired.</h4>
            <p>
              <router-link to="/" class="btn blue">
                Please try again
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
import "firebase/analytics";

export default {
  name: "SignInWithEmailExpired",
  mounted() {
    const firebaseApp = getApp();
    const analytics = getAnalytics(firebaseApp);
    logEvent(analytics, "sign_in_with_email_expired_link");
  },
};
</script>

<style lang="scss"></style>
