import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import { ModalPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faApple } from "@fortawesome/free-brands-svg-icons/faApple";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import { faRulerHorizontal } from "@fortawesome/free-solid-svg-icons/faRulerHorizontal";
import { faRulerCombined } from "@fortawesome/free-solid-svg-icons/faRulerCombined";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getAnalytics,
  setUserId,
  logEvent,
  setCurrentScreen,
} from "firebase/analytics";
import * as Sentry from "@sentry/vue";
import * as SentryBrowser from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;
library.add(
  faApple,
  faGoogle,
  faFacebookF,
  faChevronRight,
  faTrash,
  faCamera,
  faMap,
  faTag,
  faRulerHorizontal,
  faRulerCombined,
  faMapMarkerAlt,
  faTimes,
  faDownload,
  faChevronLeft,
  faEnvelope
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
Vue.use(ModalPlugin);
Vue.config.productionTip = false;
Vue.prototype.$log = console.log;

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    window.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", window.event);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", window.event);
  },
  stopProp(event) {
    event.stopPropagation();
  },
});

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYbQ_BtfgFrxGpH4gicFQG2rQ7thVVoc8",
  authDomain: "landglide-94feb.firebaseapp.com",
  databaseURL: "https://landglide-94feb.firebaseio.com",
  projectId: "landglide-94feb",
  storageBucket: "landglide-94feb.appspot.com",
  messagingSenderId: "234930406912",
  appId: "1:234930406912:web:dcbc1fe864405f86067ae5",
  measurementId: "G-SXK1H1SJQB",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.user.loggedIn) {
      next({ name: "SignIn" });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

router.afterEach((to, from, next) => {
  setCurrentScreen(analytics, to.meta.title);
  logEvent(analytics, "page_view", {
    page_location: window.location.href,
    page_path: to.path,
    page_title: to.meta.title,
  });
  logEvent(analytics, "screen_view", {
    app_name: "LandGlide Desktop",
    screen_name: to.meta.title,
  });
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://80b600e559f64a08be2410a3868d3d3a@o319432.ingest.sentry.io/5634907",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
    logErrors: true,
  });
}

let app = null;

const auth = getAuth(firebaseApp);
onAuthStateChanged(auth, (user) => {
  store.dispatch("fetchUser", user);
  if (user) {
    setUserId(analytics, user.uid);
    SentryBrowser.setUser({
      email: user.email,
      username: user.email,
      id: user.uid,
      name: user.displayName,
    });
    store.dispatch("fetchAuth");
  }
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
