<template>
  <div class="subscribe-mask">
    <div class="subscribe-modal text-center">
      <img
        src="../assets/logo_alt.svg"
        alt="LandGlide"
        width="175"
        class="ml-auto mr-auto logo"
      />
      <BButton
        v-if="!step1 && !subscriptionAdded"
        class="back-btn"
        @click="backToStep1"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </BButton>
      <h3 v-if="!expired">
        {{ headerText }}
      </h3>
      <h3 v-else-if="subscriptionAdded">Subscribed to LandGlide</h3>
      <h3 v-else-if="!step1">
        Enter payment details to continue using LandGlide.
      </h3>
      <h3 v-else>Select a payment plan to continue using LandGlide.</h3>
      <div class="signedin-info">
        <span v-once
          >Signed in as {{ user.data.displayName }} &lt;{{
            user.data.email
          }}&gt;</span
        >
        | <a href="#" @click="logout">Sign Out</a>
      </div>
      <form ref="subscriptionForm" @submit.prevent="handleSubscription">
        <div
          class="
            subscriptions
            d-flex
            align-items-end
            justify-content-center
            radio-group
          "
        >
          <div class="subscription-box green-border w-50 activated">
            <div class="savings-callout">Save 16%</div>
            <p class="type-tag">Yearly</p>
            <p class="type-tag selected-state">Billedy Annually</p>
            <p class="price-tag">
              <sup class="larger">$</sup>99<sup>.99/year</sup>
            </p>
            <input
              v-model="priceId"
              type="radio"
              name="priceId"
              title="Yearly"
              value="price_1H4SoEHwXMpPDfuzT2aaBfOX"
              checked
            />
            <p v-if="subscriptionAdded" class="purchased-text selected-state">
              Purchased
            </p>
            <img
              v-else
              src="../assets/shopping_icon.svg"
              class="selected-state shopping-icon"
              aria-label="hidden"
              width="31"
              height="31"
            />
          </div>
          <div class="subscription-box blue-border w-50">
            <p class="type-tag">Monthly</p>
            <p class="type-tag selected-state">Billed Monthly</p>
            <p class="price-tag">
              <sup class="larger">$</sup>9<sup>.99/month</sup>
            </p>
            <input
              v-model="priceId"
              type="radio"
              name="priceId"
              title="Monthly"
              value="price_1H4SoEHwXMpPDfuzPUrapdIX"
            />
            <p v-if="subscriptionAdded" class="purchased-text selected-state">
              Purchased
            </p>
            <img
              v-else
              src="../assets/shopping_icon.svg"
              class="selected-state shopping-icon"
              aria-label="hidden"
              width="31"
              height="31"
            />
          </div>
        </div>
        <div v-if="step1" class="text-area-step1 text-center">
          <h3>Unlimited access to both mobile & desktop LandGlide versions.</h3>
          <ul class="text-left checkmark-bullet">
            <li>Property Owner</li>
            <li>Sale Price</li>
            <li>Acreage</li>
            <li>Building Description</li>
            <li>Square Footage</li>
            <li>and More!</li>
          </ul>
          <p>*Applicable sales tax may apply</p>
          <p>
            <button
              id="subscribeActivateScreen2"
              class="btn green rounded"
              @click="handleStep1Subscription"
            >
              Subscribe
            </button>
          </p>
        </div>
        <div v-else-if="subscriptionAdded" class="text-area-step3 text-center">
          <p>
            <img
              src="../assets/green_check_icon.svg"
              width="42"
              height="42"
              aria-hidden="true"
            />
          </p>
          <h3>Thank You!</h3>
          <ul class="text-left checkmark-bullet full-width-checkmark-bullet">
            <li>Payment was processed by Stripe.</li>
            <li>Check your email for your receipt.</li>
          </ul>
          <p>
            <button
              id="conformationContinue"
              class="btn green rounded"
              @click="closeModal"
            >
              Continue to LandGlide
            </button>
          </p>
        </div>
        <div v-else class="form-fields stripe-fields text-left step-2-form">
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <h3 class="text-left pl-0 pr-0">Payment Details</h3>
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <label for="subscriptionName">Name<sup>*</sup></label>
                <input
                  id="subscriptionName"
                  v-model="name"
                  type="text"
                  name="name"
                  required
                />
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <label for="subscriptionAddress">Address<sup>*</sup></label>
                <input
                  id="subscriptionAddress"
                  v-model="address"
                  type="text"
                  name="address"
                  required
                />
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <label for="subscriptionAddress2">Address 2</label>
                <input
                  id="subscriptionAddress2"
                  v-model="address2"
                  type="text"
                  name="address2"
                />
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12 col-lg-6">
                <label for="subscriptionCity">City<sup>*</sup></label>
                <input
                  id="subscriptionCity"
                  v-model="city"
                  type="text"
                  name="city"
                  required
                />
              </div>
              <div class="col-12 col-lg-6">
                <label for="subscriptionCountry">Country<sup>*</sup></label>
                <div class="dropown-select">
                  <select
                    id="subscriptionCountry"
                    v-model="country"
                    name="country"
                    required
                  >
                    <option disabled value="">Choose One</option>
                    <option
                      v-for="countryOption in countries"
                      :key="countryOption.name"
                      :value="countryOption.iso2"
                    >
                      {{ countryOption.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12 col-lg-6">
                <label for="subscriptionState">State<sup>*</sup></label>
                <div class="dropown-select">
                  <select
                    id="subscriptionState"
                    v-model="state"
                    name="state"
                    required
                  >
                    <option disabled value="">Choose One</option>
                    <option
                      v-for="stateOption in states"
                      :key="stateOption.name"
                      :value="stateOption.abbr"
                    >
                      {{ stateOption.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <label for="subscriptionPostalCode"
                  >Postal Code<sup>*</sup></label
                >
                <input
                  id="subscriptionPostalCode"
                  v-model="postalCode"
                  type="text"
                  name="postalCode"
                  required
                />
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <label for="subscriptionEmail"
                  >E-Mail for Receipt<sup>*</sup></label
                >
                <input
                  id="subscriptionEmail"
                  v-model="email"
                  type="email"
                  name="email"
                  required
                />
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <h3 class="text-left pl-0 pr-0">Card Information</h3>
              </div>
            </div>
          </div>
          <div class="acct-section">
            <div class="row">
              <div class="col-12">
                <div id="card-element" class="MyCardElement" />
                <div id="card-errors" role="alert" />
              </div>
            </div>
          </div>
          <p class="d-flex justify-content-center mt-4">
            <button
              class="btn green rounded"
              :disabled="processing"
              type="submit"
            >
              {{ buttonText
              }}<img
                src="../assets/processing_icon.svg"
                class="processing-img"
                aria-label="hidden"
              />
            </button>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters } from "vuex";
import axios from "axios";
import authHeader from "../store/services/auth-header";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import { getApp } from "firebase/app";
import { BButton } from "bootstrap-vue";
export default {
  name: "Subscribe",
  analytics: null,
  auth: null,
  components: {
    BButton,
  },
  props: {
    updateSubscription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      stripe: null,
      elements: null,
      cardElement: null,
      priceId: "price_1H4SoEHwXMpPDfuzT2aaBfOX",
      name: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      processing: false,
      step1: true,
      subscriptionAdded: false,
      states: [
        { name: "Alaska", abbr: "AK" },
        { name: "Alabama", abbr: "AL" },
        { name: "Arkansas", abbr: "AR" },
        { name: "Arizona", abbr: "AZ" },
        { name: "California", abbr: "CA" },
        { name: "Colorado", abbr: "CO" },
        { name: "Connecticut", abbr: "CT" },
        { name: "Delaware", abbr: "DE" },
        { name: "Tasman", abbr: "TS" },
        { name: "Nelson", abbr: "NE" },
        { name: "Southland", abbr: "SO" },
        { name: "Marlborough", abbr: "MA" },
        { name: "Northland", abbr: "NO" },
        { name: "Waikato", abbr: "WK" },
        { name: "Northern Mariana Islands", abbr: "MP" },
        { name: "Saskatchewan", abbr: "SK" },
        { name: "Yukon", abbr: "YT" },
        { name: "Northwest Territories", abbr: "NT" },
        { name: "Nunavut", abbr: "NU" },
        { name: "Virgin Islands", abbr: "VI" },
        { name: "Manawatu-Wanganui", abbr: "MW" },
        { name: "Guam", abbr: "GU" },
        { name: "Alberta", abbr: "AB" },
        { name: "British Columbia", abbr: "BC" },
        { name: "Canterbury", abbr: "CA" },
        { name: "Otago", abbr: "OT" },
        { name: "Manitoba", abbr: "MB" },
        { name: "District Of Columbia", abbr: "DC" },
        { name: "Florida", abbr: "FL" },
        { name: "Georgia", abbr: "GA" },
        { name: "Hawaii", abbr: "HI" },
        { name: "Iowa", abbr: "IA" },
        { name: "Idaho", abbr: "ID" },
        { name: "Illinois", abbr: "IL" },
        { name: "Indiana", abbr: "IN" },
        { name: "Kansas", abbr: "KS" },
        { name: "Kentucky", abbr: "KY" },
        { name: "Louisiana", abbr: "LA" },
        { name: "Maryland", abbr: "MD" },
        { name: "Massachusetts", abbr: "MA" },
        { name: "Maine", abbr: "ME" },
        { name: "Michigan", abbr: "MI" },
        { name: "Minnesota", abbr: "MN" },
        { name: "Missouri", abbr: "MO" },
        { name: "Mississippi", abbr: "MS" },
        { name: "Montana", abbr: "MT" },
        { name: "North Carolina", abbr: "NC" },
        { name: "North Dakota", abbr: "ND" },
        { name: "Nebraska", abbr: "NE" },
        { name: "New Hampshire", abbr: "NH" },
        { name: "New Jersey", abbr: "NJ" },
        { name: "New Mexico", abbr: "NM" },
        { name: "Nevada", abbr: "NV" },
        { name: "New York", abbr: "NY" },
        { name: "Ohio", abbr: "OH" },
        { name: "Oklahoma", abbr: "OK" },
        { name: "Oregon", abbr: "OR" },
        { name: "Pennsylvania", abbr: "PA" },
        { name: "Puerto Rico", abbr: "PR" },
        { name: "Rhode Island", abbr: "RI" },
        { name: "South Carolina", abbr: "SC" },
        { name: "South Dakota", abbr: "SD" },
        { name: "Tennessee", abbr: "TN" },
        { name: "Texas", abbr: "TX" },
        { name: "Utah", abbr: "UT" },
        { name: "Virginia", abbr: "VA" },
        { name: "Vermont", abbr: "VT" },
        { name: "Washington", abbr: "WA" },
        { name: "Wisconsin", abbr: "WI" },
        { name: "West Virginia", abbr: "WV" },
        { name: "Auckland", abbr: "AU" },
        { name: "Bay of Plenty", abbr: "BP" },
        { name: "Hawke's Bay", abbr: "HB" },
        { name: "Wyoming", abbr: "WY" },
        { name: "Wellington", abbr: "WG" },
        { name: "West Coast", abbr: "WC" },
        { name: "Gisborne", abbr: "GI" },
        { name: "Taranaki", abbr: "TK" },
        { name: "Area Outside", abbr: "AO" },
        { name: "New Brunswick", abbr: "NB" },
        { name: "Newfoundland and Labrador", abbr: "NL" },
        { name: "Nova Scotia", abbr: "NS" },
        { name: "Ontario", abbr: "ON" },
        { name: "Quebec", abbr: "QC" },
        { name: "American Samoa", abbr: "AS" },
        { name: "Prince Edward Island", abbr: "PE" },
      ],
      countries: [
        { name: "Afghanistan", iso2: "AF" },
        { name: "Albania", iso2: "AL" },
        { name: "Algeria", iso2: "DZ" },
        { name: "American Samoa", iso2: "AS" },
        { name: "Andorra", iso2: "AD" },
        { name: "Angola", iso2: "AO" },
        { name: "Anguilla", iso2: "AI" },
        { name: "Antigua and Barbuda", iso2: "AG" },
        { name: "Argentina", iso2: "AR" },
        { name: "Armenia", iso2: "AM" },
        { name: "Aruba", iso2: "AW" },
        { name: "Australia", iso2: "AU" },
        { name: "Austria", iso2: "AT" },
        { name: "Azerbaijan", iso2: "AZ" },
        { name: "Bahamas", iso2: "BS" },
        { name: "Bahrain", iso2: "BH" },
        { name: "Bangladesh", iso2: "BD" },
        { name: "Barbados", iso2: "BB" },
        { name: "Belarus", iso2: "BY" },
        { name: "Belgium", iso2: "BE" },
        { name: "Belize", iso2: "BZ" },
        { name: "Benin", iso2: "BJ" },
        { name: "Bermuda", iso2: "BM" },
        { name: "Bhutan", iso2: "BT" },
        { name: "Bolivia", iso2: "BO" },
        { name: "Bosnia and Herzegovina", iso2: "BA" },
        { name: "Botswana", iso2: "BW" },
        { name: "Bouvet Island", iso2: "BV" },
        { name: "Brazil", iso2: "BR" },
        { name: "British Indian Ocean Territory", iso2: "IO" },
        { name: "British Virgin Islands", iso2: "VG" },
        { name: "Brunei Darussalam", iso2: "BN" },
        { name: "Bulgaria", iso2: "BG" },
        { name: "Burkina Faso", iso2: "BF" },
        { name: "Burma", iso2: "MM" },
        { name: "Burundi", iso2: "BI" },
        { name: "Cambodia", iso2: "KH" },
        { name: "Cameroon", iso2: "CM" },
        { name: "Canada", iso2: "CA" },
        { name: "Cape Verde", iso2: "CV" },
        { name: "Cayman Islands", iso2: "KY" },
        { name: "Central African Republic", iso2: "CF" },
        { name: "Chad", iso2: "TD" },
        { name: "Chile", iso2: "CL" },
        { name: "China", iso2: "CN" },
        { name: "Christmas Island", iso2: "CX" },
        { name: "Cocos (Keeling) Islands", iso2: "CC" },
        { name: "Colombia", iso2: "CO" },
        { name: "Comoros", iso2: "KM" },
        { name: "Congo", iso2: "CG" },
        { name: "Cook Islands", iso2: "CK" },
        { name: "Costa Rica", iso2: "CR" },
        { name: "Cote d'Ivoire", iso2: "CI" },
        { name: "Croatia", iso2: "HR" },
        { name: "Cuba", iso2: "CU" },
        { name: "Cyprus", iso2: "CY" },
        { name: "Czech Republic", iso2: "CZ" },
        { name: "Democratic Republic of the Congo", iso2: "CD" },
        { name: "Denmark", iso2: "DK" },
        { name: "Djibouti", iso2: "DJ" },
        { name: "Dominica", iso2: "DM" },
        { name: "Dominican Republic", iso2: "DO" },
        { name: "Ecuador", iso2: "EC" },
        { name: "Egypt", iso2: "EG" },
        { name: "El Salvador", iso2: "SV" },
        { name: "Equatorial Guinea", iso2: "GQ" },
        { name: "Eritrea", iso2: "ER" },
        { name: "Estonia", iso2: "EE" },
        { name: "Ethiopia", iso2: "ET" },
        { name: "Falkland Islands (Malvinas)", iso2: "FK" },
        { name: "Faroe Islands", iso2: "FO" },
        { name: "Fiji", iso2: "FJ" },
        { name: "Finland", iso2: "FI" },
        { name: "France", iso2: "FR" },
        { name: "French Guiana", iso2: "GF" },
        { name: "French Polynesia", iso2: "PF" },
        { name: "French Southern and Antarctic Lands", iso2: "TF" },
        { name: "Gabon", iso2: "GA" },
        { name: "Gambia", iso2: "GM" },
        { name: "Georgia", iso2: "GE" },
        { name: "Germany", iso2: "DE" },
        { name: "Ghana", iso2: "GH" },
        { name: "Gibraltar", iso2: "GI" },
        { name: "Greece", iso2: "GR" },
        { name: "Greenland", iso2: "GL" },
        { name: "Grenada", iso2: "GD" },
        { name: "Guadeloupe", iso2: "GP" },
        { name: "Guam", iso2: "GU" },
        { name: "Guatemala", iso2: "GT" },
        { name: "Guernsey", iso2: "GG" },
        { name: "Guinea", iso2: "GN" },
        { name: "Guinea-Bissau", iso2: "GW" },
        { name: "Guyana", iso2: "GY" },
        { name: "Haiti", iso2: "HT" },
        { name: "Heard Island and McDonald Islands", iso2: "HM" },
        { name: "Holy See (Vatican City)", iso2: "VA" },
        { name: "Honduras", iso2: "HN" },
        { name: "Hong Kong", iso2: "HK" },
        { name: "Hungary", iso2: "HU" },
        { name: "Iceland", iso2: "IS" },
        { name: "India", iso2: "IN" },
        { name: "Indonesia", iso2: "ID" },
        { name: "Iran (Islamic Republic of)", iso2: "IR" },
        { name: "Iraq", iso2: "IQ" },
        { name: "Ireland", iso2: "IE" },
        { name: "Isle of Man", iso2: "IM" },
        { name: "Israel", iso2: "IL" },
        { name: "Italy", iso2: "IT" },
        { name: "Jamaica", iso2: "JM" },
        { name: "Japan", iso2: "JP" },
        { name: "Jersey", iso2: "JE" },
        { name: "Jordan", iso2: "JO" },
        { name: "Kazakhstan", iso2: "KZ" },
        { name: "Kenya", iso2: "KE" },
        { name: "Kiribati", iso2: "KI" },
        { name: "Korea, Democratic People's Republic of", iso2: "KP" },
        { name: "Korea, Republic of", iso2: "KR" },
        { name: "Kuwait", iso2: "KW" },
        { name: "Kyrgyzstan", iso2: "KG" },
        { name: "Åland Islands", iso2: "AX" },
        { name: "Lao People's Democratic Republic", iso2: "LA" },
        { name: "Latvia", iso2: "LV" },
        { name: "Lebanon", iso2: "LB" },
        { name: "Lesotho", iso2: "LS" },
        { name: "Liberia", iso2: "LR" },
        { name: "Libyan Arab Jamahiriya", iso2: "LY" },
        { name: "Liechtenstein", iso2: "LI" },
        { name: "Lithuania", iso2: "LT" },
        { name: "Luxembourg", iso2: "LU" },
        { name: "Macau", iso2: "MO" },
        { name: "Madagascar", iso2: "MG" },
        { name: "Malawi", iso2: "MW" },
        { name: "Malaysia", iso2: "MY" },
        { name: "Maldives", iso2: "MV" },
        { name: "Mali", iso2: "ML" },
        { name: "Malta", iso2: "MT" },
        { name: "Marshall Islands", iso2: "MH" },
        { name: "Martinique", iso2: "MQ" },
        { name: "Mauritania", iso2: "MR" },
        { name: "Mauritius", iso2: "MU" },
        { name: "Mayotte", iso2: "YT" },
        { name: "Mexico", iso2: "MX" },
        { name: "Micronesia, Federated States of", iso2: "FM" },
        { name: "Monaco", iso2: "MC" },
        { name: "Mongolia", iso2: "MN" },
        { name: "Montenegro", iso2: "ME" },
        { name: "Montserrat", iso2: "MS" },
        { name: "Morocco", iso2: "MA" },
        { name: "Mozambique", iso2: "MZ" },
        { name: "Namibia", iso2: "NA" },
        { name: "Nauru", iso2: "NR" },
        { name: "Nepal", iso2: "NP" },
        { name: "Netherlands", iso2: "NL" },
        { name: "Netherlands Antilles", iso2: "AN" },
        { name: "New Caledonia", iso2: "NC" },
        { name: "New Zealand", iso2: "NZ" },
        { name: "Nicaragua", iso2: "NI" },
        { name: "Niger", iso2: "NE" },
        { name: "Nigeria", iso2: "NG" },
        { name: "Niue", iso2: "NU" },
        { name: "Norfolk Island", iso2: "NF" },
        { name: "Northern Mariana Islands", iso2: "MP" },
        { name: "Norway", iso2: "NO" },
        { name: "Oman", iso2: "OM" },
        { name: "Pakistan", iso2: "PK" },
        { name: "Palau", iso2: "PW" },
        { name: "Palestine", iso2: "PS" },
        { name: "Panama", iso2: "PA" },
        { name: "Papua New Guinea", iso2: "PG" },
        { name: "Paraguay", iso2: "PY" },
        { name: "Peru", iso2: "PE" },
        { name: "Philippines", iso2: "PH" },
        { name: "Pitcairn Islands", iso2: "PN" },
        { name: "Poland", iso2: "PL" },
        { name: "Portugal", iso2: "PT" },
        { name: "Puerto Rico", iso2: "PR" },
        { name: "Qatar", iso2: "QA" },
        { name: "Republic of Moldova", iso2: "MD" },
        { name: "Reunion", iso2: "RE" },
        { name: "Romania", iso2: "RO" },
        { name: "Russia", iso2: "RU" },
        { name: "Rwanda", iso2: "RW" },
        { name: "Saint Barthelemy", iso2: "BL" },
        { name: "Saint Helena", iso2: "SH" },
        { name: "Saint Kitts and Nevis", iso2: "KN" },
        { name: "Saint Lucia", iso2: "LC" },
        { name: "Saint Martin", iso2: "MF" },
        { name: "Saint Pierre and Miquelon", iso2: "PM" },
        { name: "Saint Vincent and the Grenadines", iso2: "VC" },
        { name: "Samoa", iso2: "WS" },
        { name: "San Marino", iso2: "SM" },
        { name: "Sao Tome and Principe", iso2: "ST" },
        { name: "Saudi Arabia", iso2: "SA" },
        { name: "Senegal", iso2: "SN" },
        { name: "Serbia", iso2: "RS" },
        { name: "Seychelles", iso2: "SC" },
        { name: "Sierra Leone", iso2: "SL" },
        { name: "Singapore", iso2: "SG" },
        { name: "Slovakia", iso2: "SK" },
        { name: "Slovenia", iso2: "SI" },
        { name: "Solomon Islands", iso2: "SB" },
        { name: "Somalia", iso2: "SO" },
        { name: "South Africa", iso2: "ZA" },
        { name: "South Georgia South Sandwich Islands", iso2: "GS" },
        { name: "Spain", iso2: "ES" },
        { name: "Sri Lanka", iso2: "LK" },
        { name: "Sudan", iso2: "SD" },
        { name: "Suriname", iso2: "SR" },
        { name: "Svalbard", iso2: "SJ" },
        { name: "Swaziland", iso2: "SZ" },
        { name: "Sweden", iso2: "SE" },
        { name: "Switzerland", iso2: "CH" },
        { name: "Syrian Arab Republic", iso2: "SY" },
        { name: "Taiwan", iso2: "TW" },
        { name: "Tajikistan", iso2: "TJ" },
        { name: "Thailand", iso2: "TH" },
        { name: "The former Yugoslav Republic of Macedonia", iso2: "MK" },
        { name: "Timor-Leste", iso2: "TL" },
        { name: "Togo", iso2: "TG" },
        { name: "Tokelau", iso2: "TK" },
        { name: "Tonga", iso2: "TO" },
        { name: "Trinidad and Tobago", iso2: "TT" },
        { name: "Tunisia", iso2: "TN" },
        { name: "Turkey", iso2: "TR" },
        { name: "Turkmenistan", iso2: "TM" },
        { name: "Turks and Caicos Islands", iso2: "TC" },
        { name: "Tuvalu", iso2: "TV" },
        { name: "Uganda", iso2: "UG" },
        { name: "Ukraine", iso2: "UA" },
        { name: "United Arab Emirates", iso2: "AE" },
        { name: "United Kingdom", iso2: "GB" },
        { name: "United Republic of Tanzania", iso2: "TZ" },
        { name: "United States", iso2: "US" },
        { name: "United States Minor Outlying Islands", iso2: "UM" },
        { name: "United States Virgin Islands", iso2: "VI" },
        { name: "Uruguay", iso2: "UY" },
        { name: "Uzbekistan", iso2: "UZ" },
        { name: "Vanuatu", iso2: "VU" },
        { name: "Venezuela", iso2: "VE" },
        { name: "Viet Nam", iso2: "VN" },
        { name: "Wallis and Futuna Islands", iso2: "WF" },
        { name: "Western Sahara", iso2: "EH" },
        { name: "Yemen", iso2: "YE" },
        { name: "Zambia", iso2: "ZM" },
        { name: "Zimbabwe", iso2: "ZW" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      expired: "expired",
      authLoading: "authLoading",
      user: "user",
    }),
    logoUrl() {
      return process.env.VUE_APP_BASE_URL + "landglide-logo-single.png";
    },
    buttonText() {
      return this.updateSubscription ? "Update" : "Subscribe";
    },
    headerText() {
      return this.updateSubscription
        ? "Update Payment Method"
        : "Subscribe to LandGlide";
    },
    endpointUri() {
      return this.updateSubscription
        ? "/rest/landglide/firebase/v1/update-payment"
        : "/rest/landglide/firebase/v1/subscribe";
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
    this.subscriptionChanged();
  },
  methods: {
    handleSubscription() {
      if (this.processing) {
        return;
      }
      this.createPaymentMethod(
        this.cardElement,
        this.priceId,
        this.name,
        this.email
      );
    },
    handleStep1Subscription() {
      if (this.step1 == true) {
        this.step1 = false;
        if ($('input[name="priceId"][title="Monthly"]').is(":checked")) {
          $(".subscriptions").addClass("selection-made");
          $(".subscription-box.blue-border").addClass("selected-box");
        } else {
          $(".subscriptions").addClass("selection-made");
          $(".subscription-box.green-border").addClass("selected-box");
        }
        this.loadStripe();
      }
    },
    backToStep1() {
      if (this.step1 == false) {
        this.step1 = true;
        $(".subscriptions").removeClass("selection-made");
        $(".subscription-box").removeClass("selected-box");
      }
    },
    showCardError(event) {
      const displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    },
    loadStripe() {
      loadStripe("pk_live_y5fuEtiJZwdQ3sESoWeue52s004DZ8OZCn")
        .then((stripe) => {
          this.stripe = stripe;
          this.elements = stripe.elements();
          const style = {
            base: {
              color: "#32325d",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontWeight: "300",
              fontSmoothing: "antialiased",
              fontSize: "14px",
              backgroundColor: "#f5f6f5",
              borderRadius: "6px",
              height: "45px",
              border: "1px solid #fff",
              width: "100%",
              padding: "14px",
              lineHeight: "45px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
              fontWeight: "400",
            },
          };
          this.cardElement = this.elements.create("card", { style: style });
          this.cardElement.mount("#card-element");
          this.cardElement.on("change", (event) => {
            this.showCardError(event);
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async createPaymentMethod(cardElement, priceId, name, email) {
      this.processing = true;
      try {
        const paymentMethodResults = await this.stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            address: {
              city: this.city,
              country: this.country,
              line1: this.address,
              line2: this.address2,
              postal_code: this.postalCode,
              state: this.state,
            },
            name: this.name,
            email: this.email,
          },
        });
        if (paymentMethodResults.error) {
          console.log(paymentMethodResults.error);
          this.processing = false;
        } else {
          await this.createSubscription({
            paymentMethodId: paymentMethodResults.paymentMethod.id,
            priceId: priceId,
            name: name,
            email: email,
          });
          this.processing = false;
        }
      } catch (exception) {
        logEvent(this.$options.analytics, "purchase_error");
        this.processing = false;
      }
    },
    async createSubscription({ paymentMethodId, priceId, name, email }) {
      const header = await authHeader();
      try {
        await axios.post(
          process.env.VUE_APP_SERVICE_BASE_URL + this.endpointUri,
          {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            name: name,
            email: email,
          },
          { headers: header }
        );

        logEvent(this.$options.analytics, "purchase");
        this.$store.dispatch("fetchAuth");
        if (this.updateSubscription) {
          this.subscriptionAdded = true;
          // this.$bvModal.hide('modalEditPayment2');
        }
      } catch (exception) {
        logEvent(this.$options.analytics, "purchase_error");
      }
    },
    async closeModal() {
      this.$bvModal.hide("modalEditPayment2");
    },
    // Note: A similar 'logout' method exists in Navigation.vue. Consider abstracting
    // for deduplication if they end up being substantially similar.
    logout() {
      signOut(this.$options.auth)
        .then(() => {
          logEvent(this.$options.analytics, "sign_out");
          this.$store.dispatch("fetchUser", null);
          this.$store.dispatch("clearAuth");
          this.$store.dispatch("clearPins");
          this.$router.push("/sign-in");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    subscriptionChanged() {
      $('input[name="priceId"]').on("change", function () {
        $(".subscription-box").removeClass("activated");
        $('input[name="priceId"]:checked')
          .parents(".subscription-box")
          .addClass("activated");
      });
    },
  },
};
</script>

<style scoped></style>
