/* eslint-disable prettier/prettier */
<template>
  <div
    class="map-elements-wrapper"
    data-label-type="owner"
  >
    <div
      id="map-container"
      style="height: calc(100vh - 108px); width: 100%"
    >
      <!-- TODO: Test alternatively: mapbox://styles/mapbox/streets-v11?optimize=true -->
      <!-- Note 2021-01-18: Removed :mapbox-gl="mapbox" since docs state that when unspecified it's
                 auto-initialized, and I know of no problem with that. -->
      <MglMap
        v-if="!active"
        key="non-interactive"
        :interactive="false"
        map-style="mapbox://styles/mapbox/streets-v11"
        :zoom="4"
        show-user-location="1"
        :center="center"
        access-token="pk.eyJ1IjoiZG9yaWFuaSIsImEiOiJja2l0NnhjY3gwaWwzMnNuZXg0aXk2Ymx0In0.Vs2NDtQCCLjClwnNwz4GDg"
      />
      <MglMap
        v-else
        key="interactive"
        map-style="mapbox://styles/mapbox/streets-v11"
        :center="center"
        :double-click-zoom="false"
        :transform-request="transformRequest"
        container="map-container"
        :zoom="4"
        access-token="pk.eyJ1IjoiZG9yaWFuaSIsImEiOiJja2l0NnhjY3gwaWwzMnNuZXg0aXk2Ymx0In0.Vs2NDtQCCLjClwnNwz4GDg"
        :attribution-control="false"
        show-user-location="1"
        @load="onMapLoad"
        @moveend="onMoveEnd"
        @contextmenu="openMenu"
      >
        <!--        There is a race condition in layer removal. All layers will attempt to remove the source.-->
        <!--        This does not seem to actually negatively impact anything, the source gets handled properly,-->
        <!--        it just throws a console error when it happens. The v-if statements control layer display based on authorization-->
        <!--        dfuhry 2020-01-18: Maybe this is because attribute clearSource is true by default? Do we want it set to false?
                    Ref: https://soal.github.io/vue-mapbox/api/Layers/#props -->

        <!--        This layer renders the parcel boundaries, supporting variable line width.-->
        <MglVectorLayer
          :source.sync="parcels"
          :layer.sync="parcelsLine"
          source-id="parcels"
          layer-id="parcels-line"
          :minzoom="16"
          :maxzoom="16"
        />

        <!--        For click-to-identify (show popup) polygons, we need to add an additional transparent polygon ("fill") layer.-->
        <!--        We need the above separate line layer because MapBox do not support fill layers with line widths > 1.-->
        <!-- dfuhry 2021-01-13: We might not need this anymore and it breaks when basemap is vector. @mouseenter="map.getCanvas().style.cursor = 'pointer';" -->
        <MglVectorLayer
          :layer.sync="parcelsFill"
          :source.sync="parcels"
          source-id="parcels"
          layer-id="parcels-fill"
          @contextmenu="parcelSelected"
          @mouseleave="highlightMouseLeave"
          @mousemove="parcelHighlight"
          @click="parcelSelected"
        />

        <!--        Very naive map labeling implementation. Probably can make the labeled field dynamic. The density is a little high-->
        <!--        we can probably lower it a bit as well, since currently it draws over a lot. Nevertheless, it's fast and built in-->
        <!--        Layer removal on state change does not seem to properly affect this layer. Not sure why.-->
        <!--        TODO: Look in to why layer removal on v-if change does not remove symbols-->
        <MglVectorLayer
          :layer.sync="parcelsLabel"
          :source.sync="parcels"
          source-id="parcels"
          layer-id="labels"
          :minzoom="16"
          :maxzoom="16"
        />

        <!--        Map pins are added to the document like this in a loop over all available pins. This is a psuedo-element like -->
        <!--        the above layers, for reactive design.-->
        <MglMarker
          v-for="pin in mapPins"
          :key="pin.id"
          :coordinates="pin.coordinates"
          @click="pinClick"
        >
          <template slot="marker">
            <img
              v-if="pin.color == 'red'"
              src="../assets/marker_red.svg"
              width="30"
              height="30"
            />
            <img
              v-else-if="pin.color == 'green'"
              src="../assets/marker_green.svg"
              width="30"
              height="30"
            />
            <img
              v-else-if="pin.color == 'yellow'"
              src="../assets/marker_yellow.svg"
              width="30"
              height="30"
            />
            <img
              v-else-if="pin.color == 'blue'"
              src="../assets/marker_blue.svg"
              width="30"
              height="30"
            />
            <img
              v-else
              src="../assets/marker_red.svg"
              width="30"
              height="30"
            />
          </template>
          <MglPopup>
            <div>
              <div class="property-popup">
                <p class="text-center mt-4">
                  <a v-b-modal="'savedLocationsModal'">
                    <strong
                      v-if="pinInfoTitle == 'address' && pin.properties.address"
                    >{{ pin.properties.address.toLowerCase() }},
                      {{ pin.properties.city }}, {{ pin.properties.state }}
                      {{ pin.properties.zip }}</strong>
                    <strong v-else-if="pinInfoTitle == 'parcel_id'">{{
                      pin.properties.parcel_id
                    }}</strong>
                    <strong v-else-if="pinInfoTitle == 'coordinates'">{{
                      pinCoordinatesPretty(pin)
                    }}</strong>
                    <strong v-else-if="pinInfoTitle == 'note'">{{
                      pin.note
                    }}</strong>
                    <strong
                      v-else-if="pinInfoTitle == 'tags'"
                      class="pin-window-title-tags"
                    ><span
                      v-for="tag in pin.tags"
                      :key="tag.tag"
                      class="pin-window-tag"
                    >{{ tag.tag }}</span></strong>
                    <strong v-else-if="pinInfoTitle == 'owner'">{{
                      pin.properties.owner
                    }}</strong>
                    <strong v-else>{{ pin.properties.owner }}</strong><br />
                    <span
                      v-if="pinInfoBody == 'address' && pin.properties.address"
                    >{{ pin.properties.address.toLowerCase() }},
                      {{ pin.properties.city }}, {{ pin.properties.state }}
                      {{ pin.properties.zip }}</span>
                    <span v-else-if="pinInfoBody == 'parcel_id'">{{
                      pin.properties.parcel_id
                    }}</span>
                    <span v-else-if="pinInfoBody == 'coordinates'">{{
                      pinCoordinatesPretty(pin)
                    }}</span>
                    <span v-else-if="pinInfoBody == 'note'">{{
                      pin.note
                    }}</span>
                    <span
                      v-else-if="pinInfoBody == 'tags'"
                      class="pin-window-body-tags"
                    ><span
                      v-for="tag in pin.tags"
                      :key="tag.tag"
                      class="pin-window-tag"
                    >{{ tag.tag }}</span></span>
                    <span v-else-if="pinInfoBody == 'owner'">{{
                      pin.properties.owner
                    }}</span>
                    <span
                      v-else-if="pin.properties.address"
                    >{{ pin.properties.address.toLowerCase() }},
                      {{ pin.properties.city }}, {{ pin.properties.state }}
                      {{ pin.properties.zip }}</span>
                    <span v-else>{{ pinCoordinatesPretty(pin) }}</span>
                  </a>
                </p>
              </div>
            </div>
          </MglPopup>
        </MglMarker>
        <MglNavigationControl position="top-right" />
        <CustomGeolocationControl
          position="top-right"
          :show-accuracy-circle="false"
        />
      </MglMap>
    </div>

    <BButton
      id="mapTypeChangeBtn"
      v-b-modal="'mapTypeModal'"
      class="btn map-btn"
      aria-label="Change Map Type"
    >
      <font-awesome-icon :icon="['fas', 'map']" />
    </BButton>
    <BModal
      id="mapTypeModal"
      ref="mapType"
      ok-only
      ok-title="Select"
    >
      <h3 class="mb-4">Select Map Type</h3>
      <div
        id="map-type-menu"
        class="radio-group"
      >
        <div class="fieldset d-flex align-items-center">
          <input
            id="base-style"
            type="radio"
            name="rtoggle"
            value="map"
            checked
          />
          <label for="base-style">Map</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="satellite-style"
            type="radio"
            name="rtoggle"
            value="satellite"
          />
          <label for="satellite-style">Satellite</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="contours-style"
            type="radio"
            name="rtoggle"
            value="contours"
          />
          <label for="contours-style">USGS Topo</label>
        </div>
      </div>
    </BModal>
    <BButton
      id="labelTypeChangeBtn"
      v-b-modal="'labelTypeModal'"
      class="btn label-btn"
      aria-label="Change Label Type"
    >
      <font-awesome-icon :icon="['fas', 'tag']" />
    </BButton>
    <BModal
      id="labelTypeModal"
      ref="labelType"
      ok-only
      ok-title="Select"
    >
      <h3 class="mb-4">Select Label Type</h3>
      <div
        id="label-type-menu"
        class="radio-group"
      >
        <div class="fieldset d-flex align-items-center">
          <input
            id="owner-label"
            type="radio"
            name="rtoggle"
            value="owner"
            checked
          />
          <label for="owner-label">Owner</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="address-label"
            type="radio"
            name="rtoggle"
            value="address"
          />
          <label for="address-label">Address</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="parcel-id-label"
            type="radio"
            name="rtoggle"
            value="parcel_id"
          />
          <label for="parcel-id-label">Parcel ID</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="owner-last-label"
            type="radio"
            name="rtoggle"
            value="owner_last_name"
          />
          <label for="owner-last-label">Owner Last Name</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="address-num-label"
            type="radio"
            name="rtoggle"
            value="addr_number"
          />
          <label for="address-num-label">Address Number</label>
        </div>
        <div class="fieldset d-flex align-items-center">
          <input
            id="none-label"
            type="radio"
            name="rtoggle"
            value="none"
          />
          <label for="none-label">None</label>
        </div>
      </div>
    </BModal>
    <button
      id="measureDistanceBtn"
      class="btn ruler-btn"
      aria-label="Measure Distance"
      title="Measure Distance"
    >
      <font-awesome-icon :icon="['fas', 'ruler-horizontal']" />
    </button>
    <button
      id="measureAreaBtn"
      class="btn area-btn"
      aria-label="Measure Area"
      title="Measure Area"
    >
      <font-awesome-icon :icon="['fas', 'ruler-combined']" />
    </button>
    <button
      id="trashMeasureBtn"
      class="btn trash-btn d-none"
      aria-label="Clear Measurements"
      title="Clear Measurements"
    >
      <font-awesome-icon :icon="['fas', 'trash']" />
    </button>
    <button
      id="addPinBtn"
      class="btn pin-btn disabled"
      aria-label="Click on a parcel to add a pin"
      title="Click on a parcel to add a pin"
    >
      <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
    </button>
    <div class="distance-measure-bar text-center">
      <p>Distance measurement: Click to add points, double-click to measure.</p>
    </div>
    <div class="area-measure-bar text-center">
      <p>Area measurement: Click to add points, double-click to measure.</p>
    </div>
    <div
      id="distance"
      class="distance-container"
    />
    <ul
      v-show="viewMenu"
      id="right-click-menu"
      ref="contextMenu"
      v-click-outside="closeMenu"
      tabindex="-1"
      :style="{ top: top, left: left }"
      @blur="closeMenu"
    >
      <li id="addPin">Add Pin</li>
      <li
        id="closeContextMenu"
        @click="closeMenu"
      >
        Close
      </li>
    </ul>
  </div>
</template>

<script>
import $ from "jquery";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { MglMap, MglNavigationControl } from "vue-mapbox";
import CustomGeolocationControl from "./CustomGeolocationControl.js";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
import { mapGetters } from "vuex";
import turfArea from "@turf/area";
import turfLength from "@turf/length";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import { BButton, BModal, VBModal } from "bootstrap-vue";

export default {
  name: "MainMap",
  analytics: null,
  auth: null,
  components: {
    MglMap,
    MglNavigationControl,
    CustomGeolocationControl,
    MglVectorLayer: () =>
      import("vue-mapbox").then(({ MglVectorLayer }) => MglVectorLayer),
    MglMarker: () => import("vue-mapbox").then(({ MglMarker }) => MglMarker),
    MglPopup: () => import("vue-mapbox").then(({ MglPopup }) => MglPopup),
    BButton,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    parcelDetailsExpanded: Boolean,
    userLocation: Promise,
    showAccuracyCircle: Boolean,
  },
  data() {
    // Note 2021-01-17: As per https://vuejs.org/v2/api/#data objects in data "must be plain ...
    // A rule of thumb is that data should just be data - it is not recommended to observe objects
    // with their own stateful behavior." Specifically, don't put "mapbox" or "map" in data.
    // Vue.observable(...) replaces their getters and setters. I.e. as per
    // https://soal.github.io/vue-mapbox/guide/basemap.html#map-loading :
    // "if you add Map object to Vuex store or component data, it may lead to weird bugs".
    // dfuhry 2020-01-17: Due to above, removed 'map: null' and 'mapbox: ...' from data vars.
    // When MapBox map has vector basemap, map's appearance is mangled (removes background colors,
    // odd street styling, etc.).
    return {
      center: [-96.4247, 31.51073],
      zoom: 16,
      parcels: {
        type: "vector",
        tiles: [
          process.env.VUE_APP_TILE_BASE_URL + "/vectortile/v1/{x}/{y}/16",
        ],
        promoteId: { parcels: "robust_id" },
        minzoom: 16,
        maxzoom: 16,
      },
      parcelsLine: {
        "id": "parcels-line",
        "source": "parcels",
        "source-layer": "parcels",
        "type": "line",
        "paint": {
          "line-color": "#9e9e9e",
          "line-width": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            4,
            2,
          ],
        },
      },
      parcelsFill: {
        "id": "parcels-fill",
        "source": "parcels",
        "source-layer": "parcels",
        "type": "fill",
        "paint": {
          "fill-outline-color": "transparent",
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            "rgba(0, 192, 128, 0.4)",
            ["boolean", ["feature-state", "hovered"], false],
            "rgba(248, 204, 137, 0.6)",
            "transparent",
          ],
        },
      },
      parcelsLabel: {
        "id": "labels",
        "type": "symbol",
        "source": "parcels",
        "source-layer": "inner_rect_center",
        "layout": {
          "text-font": ["Open Sans Regular"],
          "text-anchor": "center",
          "text-radial-offset": 0.5,
          "text-justify": "center",
          "text-field": "{owner}",
          "text-size": 16,
        },
      },
      featuresSelected: {},
      featuresHovered: {},
      idTokenCache: "",
      mapClicks: 0,
      layerStyle: null,
      labelUsed: null,
      viewMenu: false,
      top: "0px",
      left: "0px",
      display: "none",
      coordinates: [0, 0],
      distanceActivated: false,
      trashEnabled: false,
      pinInfoTitle: window.localStorage.getItem("pin_info_title"),
      pinInfoBody: window.localStorage.getItem("pin_info_body"),
      alreadyMoved: false,
    };
  },
  computed: {
    idToken() {
      // The return the cached token immediately
      return this.idTokenCache;
    },
    ...mapGetters({
      active: "active",
      expired: "expired",
      mapPins: "mapPins",
      loggedIn: "loggedIn",
    }),
  },
  watch: {
    active(isActive) {
      if (isActive && this.loggedIn) {
        this.updateToken();
        this.$store.dispatch("loadPins");
      }
    },
    loggedIn(isLoggedIn) {
      if (isLoggedIn && this.$options.auth.currentUser) {
        this.$options.auth.currentUser?.getIdToken().then((token) => {
          this.idTokenCache = token;
        });
      }
    },
  },
  created() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
    if (this.loggedIn && this.$options.auth.currentUser) {
      this.$options.auth.currentUser?.getIdToken().then((token) => {
        this.idTokenCache = token;
      });
      this.$store.dispatch("loadPins");
    }
  },
  beforeCreate() {
    // Update token in background
    if (this.loggedIn && this.$options.auth.currentUser) {
      this.$options.auth.currentUser?.getIdToken().then((token) => {
        this.idTokenCache = token;
      });
    }
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
    this.$nextTick(() => {
      if (this.active && this.loggedIn) {
        this.updateToken();
        this.$store.dispatch("loadPins");
      }
    });
    // Handle saved location view event, fired e.g. from LocationCard.vue.
    this.$root.$on("saved-location-view", (mapPin) => {
      logEvent(this.$options.analytics, "view_pin");
      this.map.jumpTo({ center: mapPin.coordinates, zoom: 17 });
    }),
    // Handle saved location delete event, fired e.g. from LocationCard.vue.
    this.$root.$on("saved-location-delete", (mapPin) => {
      logEvent(this.$options.analytics, "deleted_pin");
      this.$store.dispatch("deletePin", mapPin.id);
    }),
    this.$root.$on("saved-location-update", (mapPin) => {
      // Note: Later, can add 'images' (array), 'tags' (array), and 'color'
      // (string) to mapPinData.
      const mapPinIdAndData = {
        mapPinId: mapPin.id,
        mapPinData: {
          longitude: mapPin.coordinates[0],
          latitude: mapPin.coordinates[1],
          note: mapPin.note,
          color: mapPin.color,
          tags: mapPin.tags,
          images: mapPin.images,
        },
      };
      this.$store.dispatch("updatePin", mapPinIdAndData);
    }),
    this.$root.$on("search-result-jump-to", (llBounds) => {
      this.map.fitBounds(llBounds);
    });
    this.$root.$on("loaded-parcel-highlight", (data) => {
      this.parcelHighlight("", true, data);
      this.$emit("parcelClicked", data);
    });
  },
  methods: {
    onMapLoad(event) {
      // dfuhry 2021-01-18: Note: We no longer add the MapBox map object to data nor vuex store, as doing so would make
      // it reactive, which is wrong as per https://soal.github.io/vue-mapbox/guide/basemap.html#map-loading :
      // "if you add Map object to Vuex store or component data, it may lead to weird bugs".
      // In practice it actually worked ok when basemap was raster, but caused wrong rendering when basemap when vector.
      this.map = event.map;

      // On initial map load, set vuex mapBoundsArray to current map (viewport) bounds.
      // That's consumed by Search, etc. Note we also elsewhere update it whenever map movement ends.
      const coordLat = this.$route.query.latitude;
      const coordLng = this.$route.query.longitude;
      const robustID = this.$route.query.robustID;
      this.updateMapBoundsArray(event.map);

      const distanceContainer = document.getElementById("distance");
      const store = this.$store;
      if (coordLat && coordLng) {
        const coords = { lat: coordLat, lng: coordLng };
        event.map.flyTo({
          center: coords,
          zoom: 17,
        });
        event.map.on("moveend", () => {
          if (this.alreadyMoved === false) {
            this.moveMapAndClick(event, coords, robustID);
          }
        });
        event.map.off("moveend", () => {
          if (this.alreadyMoved === false) {
            this.moveMapAndClick(event, coords, robustID);
          }
        });
      } else {
        this.userLocation.then((coordinates) => {
          const $this = this;
          setTimeout(function() {
            $(".mapboxgl-ctrl-geolocate").click();
          }, 10);
          setTimeout(function() {
            $this.map.flyTo({
              center: { lat: coordinates.latitude, lng: coordinates.longitude },
              zoom: $this.zoom,
            });
          }, 100);
        });
      }
      // GeoJSON object to hold our measurement features
      const geojson = {
        type: "FeatureCollection",
        features: [],
      };
      // Used to draw a line between points
      const linestring = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        styles: [
          {
            id: "gl-draw-polygon-fill-inactive",
            type: "fill",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "fill-color": "#3bb2d0",
              "fill-outline-color": "#000000",
              "fill-opacity": 0.4,
            },
          },
          {
            id: "gl-draw-polygon-fill-active",
            type: "fill",
            filter: [
              "all",
              ["==", "active", "true"],
              ["==", "$type", "Polygon"],
            ],
            paint: {
              "fill-color": "#fbb03b",
              "fill-outline-color": "#000000",
              "fill-opacity": 0.4,
            },
          },
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-width": 2.5,
            },
          },
          {
            id: "measure-points",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "vertex"],
              ["!=", "mode", "static"],
            ],
            layout: {
              visibility: "visible",
            },
            paint: {
              "circle-radius": 5,
              "circle-color": "#000",
            },
          },
        ],
      });
      let area = 0;
      function updateArea(e) {
        const nfObject = new Intl.NumberFormat("en-US"); // allows easy conversion of numbers to comma format
        const data = e.features;
        if (data.length > 0) {
          area = turfArea(data[0]); // area in square meters
          // restrict to area to 2 decimal points, measurement variables.
          const sqFt = Math.round(area * 10.7639);
          const sqMiles = Math.round(area * 3.86e-7 * 1000) / 1000;
          const acres = Math.round(area * 2.47e-4 * 1000) / 1000;
          areaDescription =
            "<div class=\"distance-popup-content\">" +
            nfObject.format(sqMiles) +
            " mi² <br> " +
            nfObject.format(sqFt) +
            " ft² <br> " +
            nfObject.format(acres) +
            " acres</div>";
        } else {
          areaDescription = "";
        }
      }
      event.map.addSource("contours", {
        type: "raster",
        tiles: [
          process.env.VUE_APP_SERVICE_BASE_URL +
            "/rest/landglide/map/v1/topo/{x}/{y}/{z}",
        ],
        tileSize: 256,
      });
      event.map.addLayer({
        "id": "contours",
        "type": "raster",
        "source": "contours",
        "source-layer": "contour",
        "layout": {
          visibility: "none",
        },
      });
      event.map.addSource("satellite", {
        type: "raster",
        url: "mapbox://mapbox.satellite",
      });
      event.map.addLayer({
        "id": "satellite",
        "type": "raster",
        "source": "satellite",
        "source-layer": "satellite",
        "layout": {
          visibility: "none",
        },
      });
      event.map.addSource("geojson", {
        type: "geojson",
        data: geojson,
      });
      event.map.addLayer({
        id: "measure-points",
        type: "circle",
        source: "geojson",
        layout: {
          visibility: "visible",
        },
        paint: {
          "circle-radius": 5,
          "circle-color": "#000",
        },
        filter: ["in", "$type", "Point"],
      });
      event.map.addLayer({
        id: "measure-lines",
        type: "line",
        source: "geojson",
        layout: {
          "line-cap": "round",
          "line-join": "round",
          "visibility": "visible",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });
      event.map.addControl(draw);
      $(".mapbox-gl-draw_polygon").hide();
      $(".mapbox-gl-draw_trash").hide();

      event.map.doubleClickZoom.disable();

      $("#mapTypeChangeBtn").click(() => {
        $("#mapTypeModal .modal-footer button").click(() => {
          logEvent(this.$options.analytics, "map_changed");
          const layerVal = $("#map-type-menu input:checked").attr("value");
          if (layerVal == "map") {
            event.map.setLayoutProperty("contours", "visibility", "none");
            event.map.setLayoutProperty("satellite", "visibility", "none");
          } else {
            event.map.setLayoutProperty("contours", "visibility", "none");
            event.map.setLayoutProperty("satellite", "visibility", "none");
            event.map.setLayoutProperty(layerVal, "visibility", "visible");
          }

          // Style changes for other layers based on map type
          if (layerVal == "map" || layerVal == "contours") {
            event.map.setPaintProperty("labels", "text-color", "#000000");
            event.map.setPaintProperty(
              "labels",
              "text-halo-color",
              "transparent",
            );
            event.map.setPaintProperty("parcels-line", "line-color", "#9e9e9e");
          } else {
            event.map.setPaintProperty("labels", "text-color", "#ffffff");
            event.map.setPaintProperty("labels", "text-halo-color", "#000000");
            event.map.setPaintProperty("labels", "text-halo-width", 2);
            event.map.setPaintProperty("parcels-line", "line-color", "#F8CC89");
          }

          $(".map-elements-wrapper").attr("data-map-type", layerVal);
        });
        if ($(".map-elements-wrapper").attr("data-map-type") != "") {
          $(
            "#map-type-menu input[value=\"" +
              $(".map-elements-wrapper").attr("data-map-type") +
              "\"]",
          ).prop("checked", true);
        }
      });
      setTimeout(function() {
        if (window.localStorage.getItem("mapLabel")) {
          $(".map-elements-wrapper").attr(
            "data-label-type",
            window.localStorage.getItem("mapLabel"),
          );
        }
        if ($(".map-elements-wrapper").attr("data-label-type") != "") {
          const presetlabeltype = $(".map-elements-wrapper").attr(
            "data-label-type",
          );
          if (presetlabeltype == "none") {
            event.map.setLayoutProperty("labels", "visibility", "none");
          } else if (presetlabeltype == "address") {
            event.map.setLayoutProperty("labels", "visibility", "visible");
            event.map.setLayoutProperty(
              "labels",
              "text-field",
              "{" + presetlabeltype + "}",
            );
          } else {
            event.map.setLayoutProperty("labels", "visibility", "visible");
            event.map.setLayoutProperty(
              "labels",
              "text-field",
              "{" + presetlabeltype + "}",
            );
          }
        }
      }, 500);
      $("#labelTypeChangeBtn").click(() => {
        $("#labelTypeModal .modal-footer button").click(() => {
          logEvent(this.$options.analytics, "change_label");
          const labelType = $("#label-type-menu input:checked").attr("value");
          if (labelType == "none") {
            event.map.setLayoutProperty("labels", "visibility", "none");
          } else if (labelType == "address") {
            event.map.setLayoutProperty("labels", "visibility", "visible");
            event.map.setLayoutProperty(
              "labels",
              "text-field",
              "{" + labelType + "}",
            );
          } else {
            event.map.setLayoutProperty("labels", "visibility", "visible");
            event.map.setLayoutProperty(
              "labels",
              "text-field",
              "{" + labelType + "}",
            );
          }
          $(".map-elements-wrapper").attr("data-label-type", labelType);
          window.localStorage.setItem("mapLabel", labelType);
        });
        if ($(".map-elements-wrapper").attr("data-label-type") != "") {
          $(
            "#label-type-menu input[value=\"" +
              $(".map-elements-wrapper").attr("data-label-type") +
              "\"]",
          ).prop("checked", true);
        }
      });
      event.map.on("contextmenu", (e) => {
        const lat = e.lngLat.lat;
        const long = e.lngLat.lng;

        // console.log(event.viewMenu);
        this.setMenu(e.originalEvent.y, e.originalEvent.x);
        this.openMenu();
        $("#addPin").click(() => {
          logEvent(this.$options.analytics, "drop_pin");
          const pinData = {
            latitude: lat,
            longitude: long,
          };
          store.dispatch("addPin", pinData);
          this.closeMenu();
        });
      });
      // MEASURE FUNCTIONALITY START
      let lineset = 0;
      let justMeasureCleared = false;
      let geojsonAdditional = {
        type: "FeatureCollection",
        features: [],
      };
      // Used to draw a line between points
      let linestringAdditional = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };
      let initialTurn = true;
      let measureDescription = "";
      let measureLatLng = [0, 0];
      $("#measureDistanceBtn").click(() => {
        logEvent(this.$options.analytics, "click_distance_measurement");
        if ($(".map-elements-wrapper").hasClass("area-active")) {
          $(".map-elements-wrapper").removeClass("area-active");
          $(".area-measure-bar").fadeOut();
          draw.changeMode("simple_select");
          new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          })
            .setLngLat(areaLngLat)
            .setHTML(areaDescription)
            .addTo(event.map);
          area = 0;
        }
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          $(".map-elements-wrapper").removeClass("measure-active");
          $(".distance-measure-bar").fadeOut();
          if (justMeasureCleared == false) {
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(measureLatLng)
              .setHTML(measureDescription)
              .addTo(event.map);
            this.trashEnabled = true;
            $("#trashMeasureBtn").removeClass("d-none");
          }
        } else {
          lineset++;
          $(this).addClass("active");
          $(".map-elements-wrapper").addClass("measure-active");
          $(".distance-measure-bar").fadeIn();
          if (justMeasureCleared == false && initialTurn == false) {
            $("#trashMeasureBtn").removeClass("d-none");
          }
          $(".area-measure-bar").hide();
          $(".map-elements-wrapper").removeClass("area-active");
          $("#measureAreaBtn").removeClass("active");
          if (lineset > 0) {
            geojsonAdditional = {
              type: "FeatureCollection",
              features: [],
            };
            // Used to draw a line between points
            linestringAdditional = {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            };
            event.map.addSource("geojson" + lineset, {
              type: "geojson",
              data: geojsonAdditional,
            });
            event.map.addLayer({
              id: "measure-points" + lineset,
              type: "circle",
              source: "geojson" + lineset,
              layout: {
                visibility: "visible",
              },
              paint: {
                "circle-radius": 5,
                "circle-color": "#000",
              },
              filter: ["in", "$type", "Point"],
            });
            event.map.addLayer({
              id: "measure-lines" + lineset,
              type: "line",
              source: "geojson" + lineset,
              layout: {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible",
              },
              paint: {
                "line-color": "#000",
                "line-width": 2.5,
              },
              filter: ["in", "$type", "LineString"],
            });
            initialTurn = false;
          }
        }
      });
      let justAreaCleared = false;
      let areaDescription = "";
      let areaLngLat = [0, 0];
      $("#measureAreaBtn").click(() => {
        logEvent(this.$options.analytics, "click_area_measurement");
        if ($("#measureDistanceBtn").hasClass("active")) {
          $("#measureDistanceBtn").removeClass("active");
          $(".map-elements-wrapper").removeClass("measure-active");
          $(".distance-measure-bar").fadeOut();
          if (justMeasureCleared == false) {
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(measureLatLng)
              .setHTML(measureDescription)
              .addTo(event.map);
            this.trashEnabled = true;
          }
        }
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          $(".map-elements-wrapper").removeClass("area-active");
          $(".area-measure-bar").fadeOut();
          $("#trashMeasureBtn").removeClass("d-none");
          // ADD AREA MEASURE POPUP HERE
          if (justAreaCleared == false) {
            draw.changeMode("simple_select");
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(areaLngLat)
              .setHTML(areaDescription)
              .addTo(event.map);
          }
          area = 0;
          justAreaCleared = false;
        } else {
          $(this).addClass("active");
          $(".map-elements-wrapper").addClass("area-active");
          $(".map-elements-wrapper").removeClass("measure-active");
          $("#measureDistanceBtn").removeClass("active");
          $(".area-measure-bar").fadeIn();
          $(".distance-measure-bar").hide();
          $("#trashMeasureBtn").addClass("d-none");
          // ADD AREA MEASURE FUNCTIONALITY/FEATURES HERE
          draw.changeMode("draw_polygon");
          area = 0;

          event.map.on("draw.create", updateArea);

          event.map.on("draw.delete", updateArea);

          event.map.on("draw.update", updateArea);
        }
      });
      event.map.on("dblclick", (e) => {
        if ($(".map-elements-wrapper").hasClass("measure-active")) {
          $("#measureDistanceBtn").removeClass("active");
          $(".map-elements-wrapper").removeClass("measure-active");
          $(".distance-measure-bar").fadeOut();
          if (justMeasureCleared == false) {
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(measureLatLng)
              .setHTML(measureDescription)
              .addTo(event.map);
            this.trashEnabled = true;
            $("#trashMeasureBtn").removeClass("d-none");
          }
        } else if ($(".map-elements-wrapper").hasClass("area-active")) {
          $("#measureAreaBtn").removeClass("active");
          $(".map-elements-wrapper").removeClass("area-active");
          $(".area-measure-bar").fadeOut();
          // ADD AREA MEASURE POPUP HERE
          areaLngLat = [e.lngLat.lng, e.lngLat.lat];
          if (justAreaCleared == false) {
            draw.changeMode("simple_select");
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(areaLngLat)
              .setHTML(areaDescription)
              .addTo(event.map);
          }
          this.trashEnabled = true;
          $("#trashMeasureBtn").removeClass("d-none");
          area = 0;
          justAreaCleared = false;
        }
      });
      event.map.on("click", (e) => {
        if ($(".map-elements-wrapper").hasClass("measure-active")) {
          logEvent(this.$options.analytics, "add_measurement_point");
          justMeasureCleared = false;

          if (lineset > 1) {
            // Remove the linestring from the group
            // So we can redraw it based on the points collection
            if (geojsonAdditional.features.length > 1) {
              geojsonAdditional.features.pop();
            }

            // Clear the Distance container to populate it with a new value
            distanceContainer.innerHTML = "";

            const point = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [e.lngLat.lng, e.lngLat.lat],
              },
              properties: {
                id: String(new Date().getTime()),
              },
            };

            geojsonAdditional.features.push(point);

            if (geojsonAdditional.features.length > 1) {
              linestringAdditional.geometry.coordinates =
                geojsonAdditional.features.map(function(point) {
                  return point.geometry.coordinates;
                });

              geojsonAdditional.features.push(linestringAdditional);

              // Populate the distanceContainer with total distance
              const description =
                turfLength(linestringAdditional, {
                  units: "miles",
                }).toLocaleString() +
                " mi<br>" +
                turfLength(linestringAdditional, {
                  units: "feet",
                }).toLocaleString() +
                " ft";
              measureLatLng = [e.lngLat.lng, e.lngLat.lat];
              measureDescription =
                "<div class=\"distance-popup-content\">" + description + "</div>";
            }

            event.map.getSource("geojson" + lineset).setData(geojsonAdditional);
          } else {
            // Remove the linestring from the group
            // So we can redraw it based on the points collection
            if (geojson.features.length > 1) geojson.features.pop();

            // Clear the Distance container to populate it with a new value
            distanceContainer.innerHTML = "";

            const point = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [e.lngLat.lng, e.lngLat.lat],
              },
              properties: {
                id: String(new Date().getTime()),
              },
            };

            geojson.features.push(point);

            if (geojson.features.length > 1) {
              linestring.geometry.coordinates = geojson.features.map(function(
                point,
              ) {
                return point.geometry.coordinates;
              });

              geojson.features.push(linestring);

              // Populate the distanceContainer with total distance
              const description =
                turfLength(linestring, { units: "miles" }).toLocaleString() +
                " mi<br>" +
                turfLength(linestring, { units: "feet" }).toLocaleString() +
                " ft";
              measureLatLng = [e.lngLat.lng, e.lngLat.lat];
              measureDescription =
                "<div class=\"distance-popup-content\">" + description + "</div>";
            }

            event.map.getSource("geojson").setData(geojson);
          }
        }

        if ($(".map-elements-wrapper").hasClass("area-active")) {
          logEvent(this.$options.analytics, "add_measurement_point");
          areaLngLat = [e.lngLat.lng, e.lngLat.lat];
        }
      });

      $("#trashMeasureBtn").click(() => {
        logEvent(this.$options.analytics, "clear_measurements");
        if ($(".map-elements-wrapper").hasClass("measure-active")) {
          $("#measureDistanceBtn").removeClass("active");
          $(".map-elements-wrapper").removeClass("measure-active");
          $(".distance-measure-bar").fadeOut();
        }
        justMeasureCleared = true;
        const geoJsonClear = {
          type: "FeatureCollection",
          features: [],
        };
        event.map.getSource("geojson").setData(geoJsonClear);
        if (lineset > 0) {
          for (let i = 0; i < lineset; i++) {
            if (i > 0) {
              event.map.getSource("geojson" + (i + 1)).setData(geoJsonClear);
            }
          }
        }
        geojsonAdditional = {
          type: "FeatureCollection",
          features: [],
        };
        // Used to draw a line between points
        linestringAdditional = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        };
        $(".mapboxgl-popup").hide();
        this.trashEnabled = false;
        $(this).addClass("d-none");
        // ADD TRASH AREA MEASUREMENT FUNCTIONALITY HERE
        draw.deleteAll().getAll();
      });
      event.map.on("mousemove", function(e) {
        if ($(".map-elements-wrapper").hasClass("measure-active")) {
          // UI indicator for clicking/hovering a point on the map
          event.map.getCanvas().style.cursor = "pointer";
        }
      });
      // MEASURE FUNCTIONALITY END
    },
    moveMapAndClick(event, coords, robustID = null) {
      this.alreadyMoved = true;
      const selectedFeatures =
          event.map.queryRenderedFeatures(
            event.map.project(coords),
            { sourceLayer: "parcels", source: "parcels" },
          );
      if (robustID) {
        const selectedFeatures = event.map.querySourceFeatures("parcels", {
          sourceLayer: "parcels",
          filter: ["==", "robust_id", robustID],
        });
        if (selectedFeatures.length > 0) {
          const selectedTile = selectedFeatures[0].geometry;
          const featureCoords = {
            lat: selectedTile.coordinates[0][0][1],
            lng: selectedTile.coordinates[0][0][0],
          };
          event.map.flyTo({
            center: featureCoords,
            zoom: 17,
          });
          this.$root.$emit("loaded-parcel-highlight", selectedFeatures[0]);
          event.map.fire("click", { lngLat: featureCoords, point: event.map.project(featureCoords) });
        } else {
          const selectedFeatures =
              event.map.queryRenderedFeatures(
                event.map.project(coords),
                { sourceLayer: "parcels", source: "parcels" },
              );
          this.$root.$emit("loaded-parcel-highlight", selectedFeatures[0]);
          event.map.fire("click", { lngLat: coords, point: event.map.project(coords) });
        }
      }
      this.$root.$emit("loaded-parcel-highlight", selectedFeatures[0]);
      event.map.fire("click", { lngLat: coords, point: event.map.project(coords) });
    },
    onMoveEnd(event) {
      // Whenever map moves (finishes moving), set vuex mapBoundsArray to current map (viewport) bounds.
      // For use by e.g. Search.
      this.updateMapBoundsArray(event.map);
    },
    updateMapBoundsArray(map) {
      const mapBoundsArray = map.getBounds().toArray();
      this.$store.commit("setMapBoundsArray", mapBoundsArray);
    },
    updateToken() {
      // Update token in background
      const diff = (diffMe, diffBy) => diffMe.split(diffBy).join("");
      const oldToken = this.idTokenCache;
      return this.$options.auth.currentUser?.getIdToken().then((token) => {
        if (diff(oldToken, token)) {
          this.idTokenCache = token;
        }
      });
    },
    parcelHighlight(e, called = false, parcel = null) {
      if (called === true) {
        // run highlight if called independently in a function(i.e. being directly linked)
        this.map.setFeatureState(
          {
            source: "parcels",
            sourceLayer: "parcels",
            id: parcel.id,
          },
          { hovered: true },
        );
        this.$emit("hovering", parcel.properties);
        return;
      }
      if (this.mapClicks > 0 && !called) {
        // Ignore movement if we're in the click delay
        return;
      }
      // Un-highlight any previously selected features.
      if (this.featuresHovered && this.featuresHovered.length) {
        this.deselectParcels();
        for (let i = 0; i < e.mapboxEvent.features.length; i++) {
          this.map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: e.mapboxEvent.features[i].id,
            },
            { hovered: true },
          );
          this.$emit("hovering", e.mapboxEvent.features[i].properties);
        }
      }
      this.featuresHovered = e.mapboxEvent.features;
    },
    parcelSelected(e) {
      // console.log('parcel clicked - selected');
      // Un-highlight any previously selected features.
      if (
        !$(".map-elements-wrapper").hasClass("measure-active") &&
        !$(".map-elements-wrapper").hasClass("area-active")
      ) {
        logEvent(this.$options.analytics, "show_parcel_details");
        const features = e.mapboxEvent.features;
        const pinData = {
          latitude: e.mapboxEvent.lngLat.lat,
          longitude: e.mapboxEvent.lngLat.lng,
        };
        const storeData = this.$store;
        this.deactivateParcels();
        for (let i = 0; i < e.mapboxEvent.features.length; i++) {
          this.map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: e.mapboxEvent.features[i].id,
            },
            { selected: true },
          );
          this.$emit("selected", e.mapboxEvent.features[i].properties);
        }
        this.$emit("parcelClicked", features[0]);
        this.featuresSelected = e.mapboxEvent.features;
        $("#addPinBtn").removeClass("disabled");
        $("#addPinBtn").unbind("click");
        $("#addPinBtn").click(() => {
          logEvent(this.$options.analytics, "drop_pin");
          storeData.dispatch("addPin", pinData);
        });
      }
    },
    async parcelClicked(e) {
      const features = e.mapboxEvent.features;
      this.mapClicks++;
      if (this.mapClicks === 1) {
        setTimeout(() => {
          if (this.mapClicks === 1) {
            this.$emit("parcelClicked", features[0]);
          }
          this.mapClicks = 0;
        }, 300);
        this.parcelSelected();
        // console.log('parcel clicked');
      }
    },
    transformRequest(url, resourceType) {
      if (
        resourceType === "Tile" &&
        (url.startsWith(process.env.VUE_APP_TILE_BASE_URL) ||
          url.startsWith(process.env.VUE_APP_SERVICE_BASE_URL))
      ) {
        // This will add the necessary token to all map tile requests.
        // Make sure landglide_desktop.py also contains ('Access-Control-Allow-Headers', 'Authorization')
        this.updateToken();
        return {
          url: url,
          headers: { Authorization: "Bearer " + this.idToken },
        };
      }
    },
    deactivateParcels() {
      if (this.mapClicks > 0) {
        // Ignore movement if we're in the click delay
        return;
      }
      if (this.featuresSelected && this.featuresSelected.length) {
        logEvent(this.$options.analytics, "closed_parcel_details");
        for (let i = 0; i < this.featuresSelected.length; i++) {
          this.map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: this.featuresSelected[i].id,
            },
            { selected: false },
          );
        }
        this.featuresSelected = {};
      }
      $("#addPinBtn").addClass("disabled");
    },
    deselectParcels() {
      if (this.mapClicks > 0) {
        // Ignore movement if we're in the click delay
        return;
      }
      if (this.featuresHovered && this.featuresHovered.length) {
        for (let i = 0; i < this.featuresHovered.length; i++) {
          this.map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: this.featuresHovered[i].id,
            },
            { hovered: false },
          );
        }
      }
    },
    highlightMouseLeave() {
      if (!this.parcelDetailsExpanded && this.mapClicks === 0) {
        // dfuhry 2021-01-13: We might not need the below line anymore and it breaks when basemap is vector.
        // this.map.getCanvas().style.cursor = '';
        this.deselectParcels();
        this.$emit("hovering", null);
      }
    },
    addMarker(event) {
      // console.log('double clicked - to add marker');
      const pinData = {
        latitude: event.mapboxEvent.lngLat.lat,
        longitude: event.mapboxEvent.lngLat.lng,
      };
      this.$store.dispatch("addPin", pinData);
    },
    pinClick(event) {
      event.mapboxEvent.stopPropagation();
      event.component.togglePopup();
    },
    deletePin(pinId) {
      // console.log(['MainMap method deletePin called with pinId:', pinId]);
      this.$store.dispatch("deletePin", pinId);
    },
    setMenu: function(top, left) {
      // console.log('set menu');
      const largestHeight = window.innerHeight - 100;
      const largestWidth = window.innerWidth - 100;

      if (top > largestHeight) top = largestHeight;

      if (left > largestWidth) left = largestWidth;

      this.top = top - 100 + "px";
      this.left = left + "px";
      this.$refs.contextMenu.focus();
    },

    closeMenu: function() {
      this.viewMenu = false;
      $("#addPin").unbind("click");
    },
    openMenu: function() {
      this.viewMenu = true;
      $("#addPin").unbind("click");
    },
    pinCoordinatesPretty(mapPin) {
      // TODO: Figure out how to deduplicate this with the method in LocationCard.vue.
      // Show coordinates in Lat, Lon with cardinal directions (N / E / S / W) and non-negative number.
      // '\u00B0' is unicode character for degree symbol.
      const latNumeric = mapPin.coordinates[0];
      const latPretty =
        Math.abs(latNumeric).toFixed(6) +
        "\u00B0 " +
        (latNumeric <= 0.0 ? "W" : "E");
      const lonNumeric = mapPin.coordinates[1];
      const lonPretty =
        Math.abs(lonNumeric).toFixed(6) +
        "\u00B0 " +
        (lonNumeric <= 0.0 ? "S" : "N");
      return lonPretty + ", " + latPretty;
    },
  },
};
</script>

<style scoped>
@import "~mapbox-gl/dist/mapbox-gl.css";
</style>
