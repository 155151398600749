<template>
  <div class="location-images">
    <BCarousel
      :id="'locationImageCarousel_' + mapPin.id"
      ref="carousel"
      controls
      indicators
      fade
      :interval="0"
      class="carousel image-carousel"
    >
      <BCarouselSlide>
        <template #img>
          <BImgLazy
            blank-src="../assets/location_placeholder.jpg"
            :srcset="locationImageUrl"
            :alt="pinTitle()"
            class="w-100"
            @update:show="getLink(mapPin)"
          />
        </template>
      </BCarouselSlide>
      <BCarouselSlide v-for="item in mapPin.images" :key="item.index">
        <template #img>
          <div class="w-100 image-slide-wrapper">
            <BImgLazy
              blank-src="../assets/location_placeholder.jpg"
              :srcset="item.url"
              :alt="pinTitle()"
              class="w-100"
            />
            <BButton class="white trash-img-btn" @click="confirmDelete($event)">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </BButton>
            <div class="confirm-overlay text-center" style="display: none">
              <div>
                <p>Are you sure you want to delete this image?</p>
                <p>
                  <BButton
                    class="cancel-btn rounded white-outline"
                    @click="confirmCancel($event)"
                  >
                    Cancel
                  </BButton>
                  <BButton
                    class="delete-btn rounded red"
                    @click="deleteImage(mapPin, item)"
                  >
                    Delete
                  </BButton>
                </p>
              </div>
            </div>
          </div>
        </template>
      </BCarouselSlide>
    </BCarousel>

    <BButton class="blue img-upload-btn" @click="uploadClick">
      <font-awesome-icon :icon="['fas', 'camera']" />
    </BButton>
    <input
      ref="photo_input"
      type="file"
      style="display: none"
      accept="image/*"
      @change="previewImage"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { getApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import $ from "jquery";
import { BCarousel, BCarouselSlide, BImgLazy, BButton } from "bootstrap-vue";
import * as Sentry from "@sentry/browser";

export default {
  name: "LocationImage",
  storage: null,
  components: {
    BCarousel,
    BCarouselSlide,
    BImgLazy,
    BButton,
  },
  props: {
    mapPin: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locationImageUrl:
        "https://maps.googleapis.com/maps/api/staticmap?size=400x250&visible=" +
        this.mapPin.coordinates[1] +
        "," +
        this.mapPin.coordinates[0] +
        "&zoom=20&scale=2&maptype=hybrid&key=AIzaSyAdFiD6_C6bvgZrbLHyo1g_O8A-83JsK8c",
      img_upload: "",
      imageData: null,
      currentSlide: 0,
      slideLength: 0,
      currentSlideID: "locationImageCarousel_" + this.mapPin.id,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      loggedIn: "loggedIn",
      activeSub: "activeSub",
      active: "active",
      expired: "expired",
      auth: "auth",
      expirationDate: "expirationDate",
    }),
  },
  created() {
    if (this.mapPin.images == null) {
      this.slideLength = 0;
    } else {
      this.slideLength = this.mapPin.images.length;
    }
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.storage = getStorage(firebaseApp);
  },
  methods: {
    pinTitle(mapPin) {
      return (
        this.mapPin.properties.owner ||
        this.mapPin.coordinates.map((c) => c.toFixed(6)).join(", ")
      );
    },
    getLink(mapPin) {
      let returnValue;
      let streetviewResults;
      axios
        .get(
          "https://maps.googleapis.com/maps/api/streetview/metadata?location=" +
            mapPin.coordinates[1] +
            "," +
            mapPin.coordinates[0] +
            "&key=AIzaSyAdFiD6_C6bvgZrbLHyo1g_O8A-83JsK8c"
        )
        .then((res) => {
          streetviewResults = res.data.status;
          if (streetviewResults === "OK") {
            returnValue =
              "https://maps.googleapis.com/maps/api/streetview?size=400x250&location=" +
              mapPin.coordinates[1] +
              "," +
              mapPin.coordinates[0] +
              "&zoom=20&scale=2&maptype=hybrid&key=AIzaSyAdFiD6_C6bvgZrbLHyo1g_O8A-83JsK8c";
            this.$set(this, "locationImageUrl", returnValue);
          }
          this.checkSlideLength(this.currentSlideID);
        });
    },
    getMapPinID() {
      const carouselID = "locationImageCarousel_" + this.mapPin.id;
      return carouselID;
    },
    uploadClick() {
      this.$refs.photo_input.click();
    },
    previewImage(event) {
      this.img_upload = null;
      this.imageData = event.target.files[0];
      this.uploadImage(this.mapPin);
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0;
          const v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    uploadImage(mapPin) {
      this.img_upload = null;
      const uuid = this.uuidv4();
      const fileExtension = this.imageData.name.split(".").pop();
      const imgRef =
        "images/" +
        this.user.data.uid +
        "/map-pins/" +
        uuid +
        "." +
        fileExtension;
      const storageRef = ref(this.$options.storage, imgRef);
      uploadBytes(storageRef, this.imageData).then((snapshot) => {
        getDownloadURL(storageRef).then((url) => {
          this.img_upload = url;
          if (this.mapPin.images?.length < 5 || this.mapPin.images === null) {
            let newImages = [];
            if (this.mapPin.images?.length > 0) {
              newImages = this.mapPin.images;
            }
            const createdAt = new Date().toISOString();
            newImages.push({
              url: this.img_upload,
              created_at: createdAt,
              path: imgRef,
            });
            const newMapPin = JSON.parse(JSON.stringify(mapPin));
            newMapPin.images = newImages;
            this.slideLength = newImages.length;
            this.$root.$emit("saved-location-update", newMapPin);
            this.checkSlideLength(this.currentSlideID);
          } else {
            Sentry.setContext("mapPin", this.mapPin);
            Sentry.captureException("There are already 5 user uploaded images");
            alert(
              "There are already 5 user uploaded images - please delete one to add a new image"
            );
          }
        });
      });
    },
    deleteImage(mapPin, item) {
      const index = mapPin.images.indexOf(item);
      if (index > -1) {
        mapPin.images.splice(index, 1);
      }
      const newMapPin = JSON.parse(JSON.stringify(mapPin));
      newMapPin.images = mapPin.images;
      this.slideLength = mapPin.images.length;
      this.$root.$emit("saved-location-update", newMapPin);
      this.checkSlideLength(this.currentSlideID);
    },
    confirmDelete(event) {
      $(event.target)
        .parents(".image-slide-wrapper")
        .find(".confirm-overlay")
        .fadeIn();
      $(event.target)
        .parents(".image-carousel")
        .find(".carousel-indicators")
        .fadeOut();
      $(event.target)
        .parents(".image-carousel")
        .find(".carousel-control-next")
        .fadeOut();
      $(event.target)
        .parents(".image-carousel")
        .find(".carousel-control-prev")
        .fadeOut();
    },
    confirmCancel(event) {
      $(event.target).parents(".confirm-overlay").fadeOut();
      this.checkSlideLength(this.currentSlideID);
    },
    checkSlideLength(currentGallery) {
      if (this.slideLength > 0) {
        $("#" + currentGallery + " .carousel-indicators").show();
        $("#" + currentGallery + " .carousel-control-next").show();
        $("#" + currentGallery + " .carousel-control-prev").show();
      } else {
        $("#" + currentGallery + " .carousel-indicators").hide();
        $("#" + currentGallery + " .carousel-control-next").hide();
        $("#" + currentGallery + " .carousel-control-prev").hide();
      }
    },
  },
};
</script>

<style scoped></style>
