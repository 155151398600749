const colorTable = [
  {
    color: "Blue",
    hex: "#5173B6",
    fontColor: "#ffffff",
  },
  {
    color: "Light Blue",
    hex: "#83A1E9",
    fontColor: "#000000dd",
  },
  {
    color: "Dark Blue",
    hex: "#184886",
    fontColor: "#ffffff",
  },
  {
    color: "Red",
    hex: "#F26A65",
    fontColor: "#000000dd",
  },
  {
    color: "Light Red",
    hex: "#FF9B93",
    fontColor: "#000000dd",
  },
  {
    color: "Dark Red",
    hex: "#BA393B",
    fontColor: "#ffffff",
  },
  {
    color: "Green",
    hex: "#62BAAB",
    fontColor: "#ffffff",
  },
  {
    color: "Light Green",
    hex: "#94EDDD",
    fontColor: "#000000dd",
  },
  {
    color: "Dark Green",
    hex: "#2E8A7C",
    fontColor: "#ffffff",
  },
  {
    color: "Yellow",
    hex: "#FFD08C",
    fontColor: "#000000dd",
  },
  {
    color: "Light Yellow",
    hex: "#FFFFBD",
    fontColor: "#000000dd",
  },
  {
    color: "Dark Yellow",
    hex: "#CA9F5E",
    fontColor: "#ffffff",
  },
];

function get_string_multiplicative_hash(s) {
  let h = 0n;
  for (let i = 0; i < s.length; i++) {
    h = 31n * h + BigInt(s.charCodeAt(i));
    h = BigInt.asIntN(64, h);
  }
  return h;
}

function getTagColorObj(s) {
  const h = get_string_multiplicative_hash(s);
  let color_idx = h % BigInt(colorTable.length);
  if (color_idx < 0n) color_idx += BigInt(colorTable.length);
  return colorTable[color_idx];
}

export default getTagColorObj;
