import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export default async function authHeader() {
  const firebaseApp = getApp();
  const token = await getAuth(firebaseApp).currentUser?.getIdToken();

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}
