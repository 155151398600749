<template>
  <div class="wrap">
    <div class="header-wrapper w-100">
      <header>
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <router-link to="/" class="navbar-brand">
              <img src="../assets/logo.svg" alt="LandGlide" class="img-fluid" />
            </router-link>
            <button
              class="navbar-toggler collapsed d-none"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="icon-bar top-bar" />
              <span class="icon-bar middle-bar" />
              <span class="icon-bar bottom-bar" />
              <span class="sr-only">Toggle navigation</span>
            </button>
            <div
              id="navbarSupportedContent"
              class="collapse navbar-collapse justify-content-end show"
            >
              <div class="nav-bar">
                <div class="main-nav-wrapper">
                  <ul class="navbar-nav main-nav justify-content-end ml-auto">
                    <li class="nav-item d-flex">
                      <router-link to="/account" class="nav-link">
                        Account
                      </router-link>
                    </li>
                    <li class="nav-item d-flex">
                      <router-link to="/settings" class="nav-link">
                        Settings
                      </router-link>
                    </li>
                    <li class="nav-item d-flex">
                      <a
                        id="savedLocationsTrigger"
                        v-b-modal="'savedLocationsModal'"
                        class="nav-link"
                        >Saved Locations</a
                      >
                    </li>
                    <li class="nav-item d-flex">
                      <a href="#" class="nav-link" @click="logout">Sign Out</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
    <BModal id="savedLocationsModal" ref="hideSLModal" hide-footer hide-header>
      <button class="modal-close-btn btn grey3 rounded" @click="hideSLModal">
        <font-awesome-icon :icon="['fas', 'times']" />
      </button>
      <SavedLocations />
      <p class="d-flex justify-content-end">
        <BButton class="btn mr-2" @click="hideSLModal">
          Close Saved Locations
        </BButton>
      </p>
    </BModal>
  </div>
</template>

<script>
import "../scss/custom_styles/_header.scss";
import { getAuth, signOut } from "firebase/auth";
import { getApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapGetters } from "vuex";
import SavedLocations from "../components/SavedLocations";
import { BButton, BModal, VBModal } from "bootstrap-vue";
export default {
  name: "Navigation",
  auth: null,
  analytics: null,
  components: {
    SavedLocations: SavedLocations,
    BButton,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  computed: {
    ...mapGetters({
      user: "user",
      loggedIn: "loggedIn",
      activeSub: "activeSub",
      active: "active",
      expired: "expired",
      auth: "auth",
      expirationDate: "expirationDate",
      expirationOverride: "expirationOverride",
      authOverride: "authOverride",
      subscriptionOverride: "subscriptionOverride",
    }),
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.auth = getAuth(firebaseApp);
    this.$options.analytics = getAnalytics(firebaseApp);
  },
  methods: {
    logout() {
      const thisRoute = this.$router;
      const thisStore = this.$store;
      signOut(this.$options.auth)
        .then(() => {
          logEvent(this.$options.analytics, "sign_out");
          thisStore.dispatch("fetchUser", null);
          thisStore.dispatch("clearAuth");
          thisStore.dispatch("clearPins");
          thisRoute.push("/sign-in");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideSLModal() {
      this.$refs["hideSLModal"].hide();
    },
  },
};
</script>

<style lang="scss"></style>
