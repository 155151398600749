<template>
  <div class="saved-locations w-100 text-left align-self-start">
    <div
      class="
        container-fluid
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-12">
          <div class="body-content auth">
            <div class="row">
              <div class="col-12">
                <div
                  class="
                    top-saved-locations
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <h1>Saved Locations</h1>
                  <div
                    class="
                      button-group
                      d-flex
                      justify-content-end
                      align-items-start
                    "
                  >
                    <div class="saved-location-search">
                      <input
                        id="searchSavedLocations"
                        type="text"
                        placeholder="Search"
                        class="saved-location-search-input"
                        @keyup="searchSavedLocations($event)"
                      />
                      <span class="clear-search-input" @click="clearSearch"
                        ><img
                          src="../assets/icon_clear.svg"
                          aria-label="clear"
                          alt="clear search"
                      /></span>
                    </div>
                    <button
                      v-if="showDownloadButton()"
                      class="btn blue rounded download-btn mt-0"
                      @click="downloadSavedLocations"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'download']"
                      />&nbsp;&nbsp;Download All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="location-cards">
            <div class="row">
              <LocationCard
                v-for="mapPin in sortedPins"
                :key="mapPin.id"
                :map-pin="mapPin"
              />
            </div>
            <div v-if="!sortedPins.length" class="row mt-5">
              <div class="col-12">
                <h3>Your search did not return any results.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer d-flex">
      <div
        class="collapsed drawer-toggle"
        aria-expanded="false"
        data-toggle="collapse"
        data-target="#filterDrawer"
        @click="toggleFilterDrawer($event)"
      >
        <img src="../assets/toggle_icon.svg" aria-hidden="true" />
      </div>
      <div id="filterDrawer" class="drawer-body">
        <div class="filter-inner">
          <div class="title-bar d-flex justify-content-between">
            <h3>Filter</h3>
            <BButton
              class="rounded close-drawer-btn"
              @click="toggleFilterDrawer($event)"
            >
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </BButton>
          </div>
          <div class="filters">
            <label class="main-label">Map Pin Color</label>
            <div class="radio-group pin-options d-flex align-items-center">
              <div class="group">
                <input
                  id="mapPinColorFilter_Red"
                  type="radio"
                  class="btn-check"
                  name="mapPinColorFilter"
                  value="red"
                  autocomplete="off"
                  @change="filterMapPinColor($event)"
                />
                <label class="red" for="mapPinColorFilter_Red">
                  <img
                    src="../assets/marker_selected.svg"
                    class="checked-img"
                    alt="red icon"
                  />
                  <img
                    src="../assets/marker_red.svg"
                    class="unchecked-img"
                    alt="red icon"
                  />
                </label>
              </div>
              <div class="group">
                <input
                  id="mapPinColorFilter_Green"
                  type="radio"
                  class="btn-check"
                  name="mapPinColorFilter"
                  value="green"
                  autocomplete="off"
                  @change="filterMapPinColor($event)"
                />
                <label class="green" for="mapPinColorFilter_Green">
                  <img
                    src="../assets/marker_selected.svg"
                    class="checked-img"
                    alt="green icon"
                  />
                  <img
                    src="../assets/marker_green.svg"
                    class="unchecked-img"
                    alt="green icon"
                  />
                </label>
              </div>
              <div class="group">
                <input
                  id="mapPinColorFilter_Yellow"
                  type="radio"
                  class="btn-check"
                  name="mapPinColorFilter"
                  value="yellow"
                  autocomplete="off"
                  @change="filterMapPinColor($event)"
                />
                <label class="yellow" for="mapPinColorFilter_Yellow">
                  <img
                    src="../assets/marker_selected.svg"
                    class="checked-img"
                    alt="yellow icon"
                  />
                  <img
                    src="../assets/marker_yellow.svg"
                    class="unchecked-img"
                    alt="yellow icon"
                  />
                </label>
              </div>
              <div class="group">
                <input
                  id="mapPinColorFilter_Blue"
                  type="radio"
                  class="btn-check"
                  name="mapPinColorFilter"
                  value="blue"
                  autocomplete="off"
                  @change="filterMapPinColor($event)"
                />
                <label class="blue" for="mapPinColorFilter_Blue">
                  <img
                    src="../assets/marker_selected.svg"
                    class="checked-img"
                    alt="blue icon"
                  />
                  <img
                    src="../assets/marker_blue.svg"
                    class="unchecked-img"
                    alt="blue icon"
                  />
                </label>
              </div>
            </div>
            <label class="main-label">Tags</label>
            <div class="tag-options">
              <span
                v-for="tag in allTagsAvail"
                :key="tag.text"
                :style="tag.style"
                :data-tag="tag.text"
                class="tag-filter-option"
                @click="filterTags($event)"
                v-text="tag.text"
              />
            </div>
            <div class="clear-filters d-flex justify-content-end">
              <BButton class="rounded blue" @click="clearFilters">
                Clear
              </BButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../scss/custom_styles/_locations.scss";
import LocationCard from "../components/LocationCard";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
import { mapGetters } from "vuex";
import axios from "axios";
import $ from "jquery";
import authHeader from "@/store/services/auth-header";
import getTagColorObj from "./SavedLocationTagColoring";
import { BButton } from "bootstrap-vue";

export default {
  name: "SavedLocations",
  analytics: null,
  components: {
    LocationCard: LocationCard,
    BButton,
  },
  data() {
    return {
      colorFilter: "",
      tagFilter: [],
      searchValue: "",
    };
  },
  computed: {
    ...mapGetters({
      mapPins: "mapPins",
    }),

    sortedPins: function () {
      let sortedMapPins = [...this.mapPins].sort(function (a, b) {
        const aDate = new Date(a.created_at);
        const bDate = new Date(b.created_at);
        if (aDate > bDate) {
          return -1;
        }
        if (aDate < bDate) {
          return 1;
        }
        return 0;
      });
      if (this.searchValue != "") {
        const searchRegex = new RegExp(this.searchValue, "i");
        const searched = (pin) =>
          !this.searchValue ||
          searchRegex.test(pin.properties.owner) ||
          searchRegex.test(pin.properties.parcel_id) ||
          searchRegex.test(pin.properties.address) ||
          searchRegex.test(pin.note) ||
          searchRegex.test(pin.coordinates[0]) ||
          searchRegex.test(pin.coordinates[1]);

        const searchedMapPins = sortedMapPins.filter(searched);

        for (const pin in sortedMapPins) {
          if ({}.hasOwnProperty.call(sortedMapPins, pin)) {
            for (const pinTag in sortedMapPins[pin].tags) {
              if (
                sortedMapPins[pin].tags[pinTag].tag
                  .toLowerCase()
                  .includes(this.searchValue.toLowerCase()) &&
                !searchedMapPins.some(
                  (pin2) => pin2.id === sortedMapPins[pin].id
                )
              ) {
                searchedMapPins.push(sortedMapPins[pin]);
              }
            }
          }
        }

        sortedMapPins = searchedMapPins;
      }
      if (this.colorFilter != "" && this.tagFilter.length) {
        const filteredMapPins = sortedMapPins.filter((pin) => {
          return pin.color
            .toLowerCase()
            .includes(this.colorFilter.toLowerCase());
        });
        const currentArray = filteredMapPins;
        const newArray = [];
        for (const index in this.tagFilter) {
          if ({}.hasOwnProperty.call(this.tagFilter, index)) {
            const tag = this.tagFilter[index].text;
            for (const pin in currentArray) {
              if ({}.hasOwnProperty.call(currentArray, pin)) {
                for (const pinTag in currentArray[pin].tags) {
                  if (
                    currentArray[pin].tags[pinTag].tag.toLowerCase() ===
                    tag.toLowerCase()
                  ) {
                    newArray.push(currentArray[pin]);
                  }
                }
              }
            }
          }
        }
        return newArray;
      } else if (this.colorFilter != "") {
        const filteredMapPins = sortedMapPins.filter((pin) => {
          return pin.color
            .toLowerCase()
            .includes(this.colorFilter.toLowerCase());
        });
        return filteredMapPins;
      } else if (this.tagFilter.length) {
        const currentArray = sortedMapPins;
        const newArray = [];
        for (const index in this.tagFilter) {
          if ({}.hasOwnProperty.call(this.tagFilter, index)) {
            const tag = this.tagFilter[index].text;
            for (const pin in currentArray) {
              if ({}.hasOwnProperty.call(currentArray, pin)) {
                for (const pinTag in currentArray[pin].tags) {
                  if (
                    currentArray[pin].tags[pinTag].tag.toLowerCase() ===
                    tag.toLowerCase()
                  ) {
                    newArray.push(currentArray[pin]);
                  }
                }
              }
            }
          }
        }
        return newArray;
      } else {
        return sortedMapPins;
      }
    },
    allTagsAvail: function () {
      const tagsArray = [];
      this.mapPins.forEach((e) => {
        if (e.tags) {
          e.tags.forEach((t) => {
            tagsArray.push(t);
          });
        }
      });
      const unique = Array.from(new Set(tagsArray.map((s) => s.tag))).map(
        (tag) => {
          return {
            tag: tag,
          };
        }
      );
      const VisualTags = [];
      const AutoCompleteTags = [];
      for (const val in unique) {
        if ({}.hasOwnProperty.call(unique, val)) {
          const colorObj = getTagColorObj(unique[val].tag);
          const visualObj = {
            text: unique[val].tag,
            style:
              "color: " +
              colorObj.fontColor +
              "; background-color: " +
              colorObj.hex +
              ";",
          };
          const autoObj = {
            text: unique[val].tag,
          };
          VisualTags.push(visualObj);
          AutoCompleteTags.push(autoObj);
        }
      }
      window.localStorage.setItem(
        "autocompleteItems",
        JSON.stringify(AutoCompleteTags)
      );
      return VisualTags;
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    logEvent(this.$options.analytics, "click_saved_locations");
    if (this.$route.name != "MapView") {
      this.$router.push("/");
    }
  },
  destroyed() {
    logEvent(this.$options.analytics, "hide_saved_locations");
  },
  methods: {
    async downloadSavedLocations() {
      logEvent(this.$options.analytics, "export_csv");
      // DOWNLOAD LOCATIONS FUNCTION
      const headers = await authHeader();
      const response = await axios.get(
        process.env.VUE_APP_SERVICE_BASE_URL +
          "/rest/landglide/map/v2/pin/export/csv",
        { headers: headers, responseType: "blob" }
      );
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "landglide-saved-locations.csv";
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    toggleFilterDrawer: function (event) {
      if ($(".drawer-toggle").attr("aria-expanded") == "false") {
        $(".drawer-toggle").attr("aria-expanded", "true");
        $("#filterDrawer").addClass("show");
      } else {
        $(".drawer-toggle").attr("aria-expanded", "false");
        $("#filterDrawer").removeClass("show");
      }
    },
    // disable download button if no saved mapPins
    showDownloadButton: function () {
      return this.mapPins?.length;
    },
    searchSavedLocations: function (event) {
      this.searchValue = event.target.value;
    },
    filterMapPinColor: function (event) {
      const data = event.target.value;
      this.colorFilter = data;
    },
    filterTags: function (event) {
      if (!$(event.target).hasClass("selected")) {
        $(".tag-filter-option").removeClass("selected");
        const tag = $(event.target).attr("data-tag");
        $(event.target).addClass("selected");
        if ($(".tag-options .selected").length) {
          $(".tag-options").addClass("selection-made");
        } else {
          $(".tag-options").removeClass("selection-made");
        }
        this.tagFilter = [{ text: tag, style: $(event.target).attr("style") }];
      }
    },
    clearFilters: function () {
      this.colorFilter = "";
      this.tagFilter = [];
      $(".tag-filter-option").removeClass("selected");
      $(".tag-options").removeClass("selection-made");
      const elements = document.getElementsByName("mapPinColorFilter");
      for (let i = 0; i < elements.length; i++) {
        elements[i].checked = false;
        elements[i].removeAttribute("checked");
      }
    },
    clearSearch: function () {
      $("#searchSavedLocations").val("");
      this.searchValue = "";
    },
  },
};
</script>

<style lang="scss"></style>
