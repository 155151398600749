<template>
  <div class="location col-12 col-md-6 col-lg-4">
    <div class="inner-wrapper h-100">
      <!-- TODO(dfuhry): Update this placeholder image value to show what's in probably base64-encoded mapPin.properties.images[0] -->
      <LocationImage :map-pin="mapPin" />
      <div class="text-wrapper">
        <h3 v-text="pinTitle()" />
        <div class="info-section">
          <div v-if="mapPin.note">
            <strong>Note</strong>
          </div>
          <div v-if="mapPin.note" class="note" v-text="mapPin.note" />
          <div v-else class="note">
            <a v-b-modal="'modalEditNote' + mapPin.id" class="addNoteLink"
              ><img src="../assets/note_add.svg" />&nbsp;&nbsp;&nbsp;Add Note</a
            >
          </div>
        </div>
        <div class="info-section">
          <VueTagsInput
            v-model="tag"
            :tags="visualTags"
            :autocomplete-items="filteredItems"
            :autocomplete-min-length="0"
            @tags-changed="(allTags) => tagsChanged(allTags, mapPin)"
          />
        </div>
        <div class="info-section">
          <div><strong>Owner</strong></div>
          <div class="owner" v-text="mapPin.properties.owner" />
        </div>
        <div class="info-section">
          <div><strong>Address</strong></div>
          <div class="address" v-text="mapPin.properties.address" />
        </div>
        <div class="info-section">
          <div><strong>Parcel ID</strong></div>
          <div class="parcelId" v-text="mapPin.properties.parcel_id" />
        </div>
        <div class="info-section">
          <div class="radio-group pin-options d-flex align-items-center">
            <div class="group">
              <input
                :id="redid"
                type="radio"
                class="btn-check"
                :name="radiogroupid"
                value="red"
                autocomplete="off"
                :checked="mapPin.color == 'red' || mapPin.color == null"
                @change="changePinColor($event, mapPin)"
              />
              <label class="red" :for="redid">
                <img
                  src="../assets/marker_selected.svg"
                  class="checked-img"
                  alt="red icon"
                />
                <img
                  src="../assets/marker_red.svg"
                  class="unchecked-img"
                  alt="red icon"
                />
              </label>
            </div>
            <div class="group">
              <input
                :id="greenid"
                type="radio"
                class="btn-check"
                :name="radiogroupid"
                value="green"
                autocomplete="off"
                :checked="mapPin.color == 'green'"
                @change="changePinColor($event, mapPin)"
              />
              <label class="green" :for="greenid">
                <img
                  src="../assets/marker_selected.svg"
                  class="checked-img"
                  alt="green icon"
                />
                <img
                  src="../assets/marker_green.svg"
                  class="unchecked-img"
                  alt="green icon"
                />
              </label>
            </div>
            <div class="group">
              <input
                :id="yellowid"
                type="radio"
                class="btn-check"
                :name="radiogroupid"
                value="yellow"
                autocomplete="off"
                :checked="mapPin.color == 'yellow'"
                @change="changePinColor($event, mapPin)"
              />
              <label class="yellow" :for="yellowid">
                <img
                  src="../assets/marker_selected.svg"
                  class="checked-img"
                  alt="yellow icon"
                />
                <img
                  src="../assets/marker_yellow.svg"
                  class="unchecked-img"
                  alt="yellow icon"
                />
              </label>
            </div>
            <div class="group">
              <input
                :id="blueid"
                type="radio"
                class="btn-check"
                :name="radiogroupid"
                value="blue"
                autocomplete="off"
                :checked="mapPin.color == 'blue'"
                @change="changePinColor($event, mapPin)"
              />
              <label class="blue" :for="blueid">
                <img
                  src="../assets/marker_selected.svg"
                  class="checked-img"
                  alt="blue icon"
                />
                <img
                  src="../assets/marker_blue.svg"
                  class="unchecked-img"
                  alt="blue icon"
                />
              </label>
            </div>
          </div>
        </div>
        <p class="links d-flex align-items-center justify-content-between">
          <button
            class="btn green rounded mt-0"
            @click="viewParcelDetail(mapPin)"
          >
            View
          </button>
          <BButton
            v-b-modal="'modalEditNote' + mapPin.id"
            class="btn grey2 rounded"
          >
            Note
          </BButton>
          <BButton
            v-b-modal="'modalDelete' + mapPin.id"
            class="btn grey2 rounded"
          >
            Delete
          </BButton>
        </p>
      </div>
    </div>
    <BModal :id="'modalEditNote' + mapPin.id" ref="hideModal" hide-footer>
      <template #modal-title>
        <h2>Note</h2>
      </template>
      <textarea id="editableNote" v-model="localNote" />
      <p class="d-flex justify-content-end">
        <BButton class="btn mr-2" @click="hideModal"> Cancel </BButton>
        <BButton class="btn green mt-0" @click="saveNote(mapPin)">
          Save Note
        </BButton>
      </p>
    </BModal>
    <BModal :id="'modalDelete' + mapPin.id" ref="hideModal" hide-footer>
      <template #modal-title>
        <h2>Delete</h2>
      </template>
      <p class="my-4">
        Delete your saved location '<span v-text="pinTitleAbbrev(mapPin)" />'?
      </p>
      <p class="d-flex justify-content-end">
        <BButton class="btn mr-2" @click="hideModal"> Cancel </BButton>
        <BButton class="btn green mt-0" @click="deleteLocation(mapPin)">
          Delete
        </BButton>
      </p>
    </BModal>
  </div>
</template>

<script>
import LocationImage from "../components/LocationImage";
import VueTagsInput from "@johmun/vue-tags-input";
import getTagColorObj from "./SavedLocationTagColoring";
import { BButton, BModal, VBModal } from "bootstrap-vue";

export default {
  name: "LocationCard",
  components: {
    LocationImage: LocationImage,
    VueTagsInput,
    BButton,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    mapPin: {
      type: Object,
      default: null,
    },
  },
  data() {
    // origLocalNote used for restoring value in text box when 'Cancel' is pressed.
    return {
      localNote: this.mapPin.note,
      origLocalNote: this.mapPin.note,
      selectedColor: this.mapPin.color,
      radiogroupid: this.mapPin.id + "_map_pin",
      redid: this.mapPin.id + "_pin_red",
      yellowid: this.mapPin.id + "_pin_yellow",
      greenid: this.mapPin.id + "_pin_green",
      blueid: this.mapPin.id + "_pin_blue",
      tag: "",
      tags: this.mapPin.tags,
      visualTags: [],
      autocompleteItems: JSON.parse(
        window.localStorage.getItem("autocompleteItems")
      ),
    };
  },

  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  created() {
    if (this.tags == null) {
      this.tags = [];
    }
    const newVisualTags = [];
    for (const val in this.tags) {
      if ({}.hasOwnProperty.call(this.tags, val)) {
        const colorObj = getTagColorObj(this.tags[val].tag);
        const visualObj = {
          text: this.tags[val].tag,
          style:
            "color: " +
            colorObj.fontColor +
            "; background-color: " +
            colorObj.hex +
            ";",
        };
        newVisualTags.push(visualObj);
      }
    }
    this.visualTags = newVisualTags;
    // console.log(this.autocompleteItems);
  },
  methods: {
    pinCoordinatesPretty(mapPin) {
      // TODO: Figure out how to deduplicate this with the identical method in MainMap.vue.
      // Show coordinates in Lat, Lon with cardinal directions (N / E / S / W) and non-negative number.
      // '\u00B0' is unicode character for degree symbol.
      const latNumeric = this.mapPin.coordinates[0];
      const latPretty =
        Math.abs(latNumeric).toFixed(6) +
        "\u00B0 " +
        (latNumeric <= 0.0 ? "W" : "E");
      const lonNumeric = this.mapPin.coordinates[1];
      const lonPretty =
        Math.abs(lonNumeric).toFixed(6) +
        "\u00B0 " +
        (lonNumeric <= 0.0 ? "S" : "N");
      return lonPretty + ", " + latPretty;
    },
    pinTitle(mapPin) {
      let title = "(no title)"; // A bug if literally displayed; mapPin should at least have coordinates.
      if (this.mapPin.properties.owner) {
        title = this.mapPin.properties.owner;
      } else {
        title = this.pinCoordinatesPretty(mapPin);
      }
      return title;
    },
    pinTitleAbbrev(mapPin) {
      let title = this.pinTitle(mapPin);
      const titleAbbrevMaxLen = 32;
      if (title.length > titleAbbrevMaxLen) {
        // Truncate and add ellipsis (one-character dot dot dot).
        title = title.substr(0, titleAbbrevMaxLen - 1) + "\u2026";
      }
      return title;
    },
    deleteLocation(mapPin) {
      // Emit 'map-pin-delete' event on root. Handled in MainMap.vue.
      // Can't do the actual delete here because we don't have a reference to mapPins.
      this.$root.$emit("saved-location-delete", mapPin);
      // dan 2021-02-17: This does not appear to be a real function
      // this.$bvModal.reload();
    },
    hideModal() {
      this.$refs["hideModal"].hide();
      this.localNote = this.origLocalNote;
    },
    saveNote(mapPin) {
      // Clone mapPin.
      const newMapPin = JSON.parse(JSON.stringify(mapPin));
      newMapPin.note = this.localNote;
      this.origLocalNote = this.localNote; // Update var backing edit text box to reflect saved note.
      this.$root.$emit("saved-location-update", newMapPin);
      this.$bvModal.hide("modalEditNote" + mapPin.id);
    },
    savePinColor(mapPin) {
      const newMapPin = JSON.parse(JSON.stringify(mapPin));
      newMapPin.color = this.selectedColor;
      this.$root.$emit("saved-location-update", newMapPin);
    },
    viewParcelDetail(mapPin) {
      // Emit 'saved-location-view' event on root. Handled in MainMap.vue.
      this.$root.$emit("saved-location-view", mapPin);
      this.$bvModal.hide("savedLocationsModal");
    },
    changePinColor(event, mapPin) {
      const data = event.target.value;
      this.selectedColor = data;
      const newMapPin = JSON.parse(JSON.stringify(mapPin));
      newMapPin.color = this.selectedColor;
      this.$root.$emit("saved-location-update", newMapPin);
    },
    tagsChanged(tags, mapPin) {
      const newTags = [];
      const newVisualTags = [];
      for (const val in tags) {
        if ({}.hasOwnProperty.call(tags, val)) {
          const colorObj = getTagColorObj(tags[val].text);
          const newobj = {
            tag: tags[val].text,
          };
          const visualObj = {
            text: tags[val].text,
            style:
              "color: " +
              colorObj.fontColor +
              "; background-color: " +
              colorObj.hex +
              ";",
          };
          const autoObj = {
            text: tags[val].text,
          };
          newTags.push(newobj);
          newVisualTags.push(visualObj);
          if (this.autocompleteItems.indexOf(autoObj) < 0) {
            this.autocompleteItems.push(autoObj);
            window.localStorage.setItem(
              "autocompleteItems",
              JSON.stringify(this.autocompleteItems)
            );
          }
        }
      }
      this.tags = newTags;
      this.visualTags = newVisualTags;

      const newMapPin = JSON.parse(JSON.stringify(mapPin));
      newMapPin.tags = this.tags;
      console.log(newMapPin);
      this.$root.$emit("saved-location-update", newMapPin);
    },
  },
};
</script>

<style lang="scss"></style>
