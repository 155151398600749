<template>
  <div class="settings w-100 text-left align-self-start">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-start
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="body-content auth" style="float:left">
            <h1>Settings</h1>
            <div class="acct-section">
              <h2>
                <font-awesome-icon :icon="['fas', 'map-marker-alt']" /> Pin Info
                Window
              </h2>
              <form class="map-info-window-settings">
                <div class="form-row row">
                  <div class="col-12 col-lg-6 fieldset">
                    <label for="pinInfoTitle">Title</label>
                    <div class="styled-select">
                      <select
                        id="pinInfoTitle"
                        v-model="pinInfoTitle"
                        name="pinInfoTitle"
                        :value="pinInfoTitle"
                      >
                        <option value="owner">Owner Name</option>
                        <option value="address">Address</option>
                        <option value="parcel_id">Parcel ID</option>
                        <option value="coordinates">Coordinates</option>
                        <option value="note">Notes</option>
                        <option value="tags">Tags</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 fieldset">
                    <label for="pinInfoBody">Body</label>
                    <div class="styled-select">
                      <select
                        id="pinInfoBody"
                        v-model="pinInfoBody"
                        name="pinInfoBody"
                        :value="pinInfoBody"
                      >
                        <option value="owner">Owner Name</option>
                        <option value="address">Address</option>
                        <option value="parcel_id">Parcel ID</option>
                        <option value="coordinates">Coordinates</option>
                        <option value="note">Notes</option>
                        <option value="tags">Tags</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 save-info d-flex align-items-center">
                    <a class="btn green rounded" @click="submit">Save</a>
                    <p class="success-message" style="display: none">
                      <em
                        >Your Pin Info Window settings have successfully been
                        saved.</em
                      >
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div class="acct-section">
              <h2>Search Features</h2>
              <p style="float:left">
                <b>Search Auto-Complete</b><br />

                Displays suggested search results based on text being entered.<br />
              </p>
              <p style="float:right">
                <label class="switch">
                  <input
                    id="searchAutocomplete"
                    type="checkbox"
                    @change="toggleAutocomplete"
                    :checked="searchAutocomplete == 'true'"
                  >
                  <span class="slider round"></span>
                </label>
              </p>
              <br>
              <p style="float:left">
                <b>Recently Viewed Searches</b><br />

                Displays most recently viewed properties found via text search.<br />
              </p>
              <p style="float:right">
                <label class="switch">
                  <input
                      id="recentlyViewed"
                      type="checkbox"
                      @change="toggleRecentlyViewed"
                      :checked="searchRecentlyViewed == 'true'"
                  >
                  <span class="slider round"></span>
                </label>
              </p>
            </div>
            <div class="acct-section">
              <h2>Contact Us</h2>
              <p>
                Send our support team an email<br />
                <a
                  href="mailto:desktop-support@landglide.com?subject=LandGlide Support"
                  class="btn green rounded"
                  >Contact Support</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
export default {
  name: "Settings",
  analytics: null,
  data() {
    return {
      pinInfoTitle: window.localStorage.getItem("pin_info_title"),
      pinInfoBody: window.localStorage.getItem("pin_info_body"),
      searchAutocomplete: window.localStorage.getItem("search_autocomplete"),
      searchRecentlyViewed: window.localStorage.getItem("search_recently_viewed"),
    };
  },
  computed: {
    storedTitle() {
      return window.localStorage.getItem("pin_info_title");
    },
    storedBody() {
      return window.localStorage.getItem("pin_info_body");
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    logEvent(this.$options.analytics, "settings_shown");
  },
  destroyed() {
    logEvent(this.$options.analytics, "settings_hidden");
  },
  created() {
    if (this.pinInfoTitle == "" || this.pinInfoTitle == null) {
      this.pinInfoTitle = "owner";
    }
    if (this.pinInfoBody == "" || this.pinInfoBody == null) {
      this.pinInfoBody = "address";
    }
    if (this.searchAutocomplete == "" || this.searchAutocomplete == null) {
      this.searchAutocomplete = "true";
    }
    if (this.searchRecentlyViewed == "" || this.searchRecentlyViewed == null) {
      this.searchRecentlyViewed = "true";
    }
  },
  methods: {
    submit() {
      window.localStorage.setItem("pin_info_title", this.pinInfoTitle);
      window.localStorage.setItem("pin_info_body", this.pinInfoBody);
      $(".success-message").show();
    },
    toggleAutocomplete(e) {
      window.localStorage.setItem("search_autocomplete", e.target["checked"]);
    },
    toggleRecentlyViewed(e) {
      window.localStorage.setItem("search_recently_viewed", e.target["checked"]);
    },
  },
};
</script>

<style lang="scss">
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
