export const user = {
  state: {
    user: {
      loggedIn: false,
      data: null,
      customProfilePic: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    loggedIn(state, getters) {
      if (getters.auth.firebase_user_id != null) {
        return true;
      } else {
        return state.user.loggedIn && !getters.authOverride;
      }
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        let displayName = user.displayName;
        let photoUrl = user.photoURL;
        let uid = user.uid;
        if (!displayName || !photoUrl || !uid) {
          user.providerData.forEach((info) => {
            if (info && info.displayName) {
              displayName = info.displayName;
            }
            if (info && info.photoURL) {
              photoUrl = info.photoURL;
            }
            if (info && info.uid) {
              uid = info.uid;
            }
          });
        }
        commit("SET_USER", {
          displayName: displayName,
          email: user.email,
          photoUrl: photoUrl,
          uid: user.uid,
        });
      } else {
        commit("SET_USER", null);
      }
    },
  },
};
