import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "@/views/SignIn.vue";
import SignInWithEmail from "@/views/SignInWithEmail.vue";
import SignInWithEmailConfirmation from "@/views/SignInWithEmailConfirmation.vue";
import SignInWithEmailExpired from "@/views/SignInWithEmailExpired.vue";
import SignInWithEmailLink from "@/views/SignInWithEmailLink";
import NotFound from "@/components/NotFound.vue";
import MyAccount from "@/views/MyAccount.vue";
import Settings from "@/views/Settings.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import MapView from "@/views/Map.vue";
import Onboarding from "@/views/Onboarding.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "MapView",
    component: MapView,
    meta: {
      requiresAuth: true,
      title: "Map",
    },
  },
  {
    path: "/map",
    redirect: (to) => {
      return {
        path: "/",
      };
    },
  },
  {
    path: "/continue-with-email",
    name: "SignInWithEmail",
    component: SignInWithEmail,
    meta: {
      title: "Email SignIn",
    },
  },
  {
    path: "/continue-with-email/confirm",
    name: "SignInWithEmailConfirmation",
    component: SignInWithEmailConfirmation,
    meta: {
      title: "Email SignIn Confirm",
    },
  },
  {
    path: "/continue-with-email/expired",
    name: "SignInWithEmailExpired",
    component: SignInWithEmailExpired,
    meta: {
      title: "Email SignIn Expired",
    },
  },
  {
    path: "/continue-with-email/sign-in",
    name: "SignInWithEmailLink",
    component: SignInWithEmailLink,
    meta: {
      title: "Email SignIn Link",
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/account",
    name: "MyAccount",
    component: MyAccount,
    meta: {
      requiresAuth: true,
      title: "Account",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: "Settings",
    },
  },
  {
    path: "/terms",
    name: "TermsOfService",
    component: TermsOfService,
    meta: {
      title: "Terms",
    },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: Onboarding,
    meta: {
      title: "Onboarding",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

export default router;
