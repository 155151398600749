<template>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <div class="d-flex text-row">
            <h4><strong>Need Help?</strong></h4>
            <p @click="logSupportClick">
              <a
                href="https://www.landglide.com/faqs-support/"
                target="_blank"
                class="cta-link light-cta-link next-link"
              >Support & FAQs<font-awesome-icon
                :icon="['fas', 'chevron-right']"
              /></a>
            </p>
            <p @click="logFaqClick">
              <a
                href="https://linktr.ee/LandGlideApp"
                target="_blank"
                class="cta-link light-cta-link next-link"
              >Social <font-awesome-icon
                :icon="['fas', 'chevron-right']"
              /></a>
            </p>
          </div>
          <div>
            <p>
              Contact customer support
              <a
                href="mailto:desktop-support@landglide.com"
                @click="logEmailClick"
              >desktop-support@landglide.com</a>
            </p>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="row">
          <div class="col-12 text-left">
            All content 2020 Real Estate Portal, USA. U.S. Patent No. 8,255,418
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import "../scss/custom_styles/_footer.scss";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
export default {
  name: "Footer",
  analytics: null,
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
  },
  methods: {
    logFaqClick() {
      logEvent(this.$options.analytics, "click_faq");
    },
    logSupportClick() {
      logEvent(this.$options.analytics, "click_support");
    },
    logEmailClick() {
      logEvent(this.$options.analytics, "contact_support_from_footer");
    },
  },
};
</script>

<style lang="scss"></style>
