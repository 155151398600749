<template>
  <div class="sign-in w-100">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <img
              src="../assets/mail_icon.svg"
              alt="email icon"
              aria-hidden="true"
              class="img-fluid mt-5 mb-3"
            />
            <h1>Check Your Email</h1>
            <hr />
            <h4>
              We’ve sent a magic link to {{ email }}. Click the link to sign in
              or create your account.
            </h4>
            <p>
              <router-link to="/" class="btn blue next-btn">
                OK<font-awesome-icon :icon="['fas', 'chevron-right']" />
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInWithEmailConfirmation",
  data() {
    return {
      email: window.localStorage.getItem("emailForSignIn"),
    };
  },
};
</script>

<style lang="scss"></style>
