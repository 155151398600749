import axios from "axios";
import authHeader from "./auth-header";

class MapPinsService {
  async getAll() {
    const headers = await authHeader();
    const response = await axios.get(
      process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/map/v2/pin",
      { headers: headers }
    );
    localStorage.setItem("mapPins", JSON.stringify(response.data));
    return response.data;
  }
  async addPin(pinData) {
    const headers = await authHeader();
    const response = await axios.post(
      process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/map/v2/pin",
      pinData,
      { headers: headers }
    );
    const storedPins = JSON.parse(localStorage.getItem("mapPins"));
    storedPins.push(response.data);
    localStorage.setItem("mapPins", JSON.stringify(storedPins));
    return response.data;
  }
  async updatePin(mapPinIdAndData) {
    const headers = await authHeader();
    const pinId = mapPinIdAndData.mapPinId;
    const pinData = mapPinIdAndData.mapPinData;
    const response = await axios.patch(
      process.env.VUE_APP_SERVICE_BASE_URL +
        "/rest/landglide/map/v2/pin/" +
        pinId,
      pinData,
      { headers: headers }
    );
    const storedPins = JSON.parse(localStorage.getItem("mapPins"));
    const pinIndex = storedPins.findIndex(
      (storedPin) => pinId === storedPin.id
    );
    if (pinIndex !== -1) {
      storedPins.splice(pinIndex, 1);
    } else {
      console.log(
        "MapPinsService updatePin Warning: pinIndex unexpectedly not found for id " +
          pinId
      );
    }
    storedPins.push(response.data);
    localStorage.setItem("mapPins", JSON.stringify(storedPins));
    return response.data;
  }
  async deletePin(pinId) {
    const headers = await authHeader();
    await axios.delete(
      process.env.VUE_APP_SERVICE_BASE_URL +
        "/rest/landglide/map/v2/pin/" +
        pinId,
      { headers: headers }
    );
    const storedPins = JSON.parse(localStorage.getItem("mapPins"));
    const pinIndex = storedPins.findIndex((pid) => pinId === pid.id);
    if (pinIndex !== -1) {
      storedPins.splice(pinIndex, 1);
    } else {
      console.log(
        "MapPinsService deletePin Warning: pinIndex unexpectedly not found for id " +
          pinId
      );
    }
    localStorage.setItem("mapPins", JSON.stringify(storedPins));
  }
  clearPins() {
    localStorage.removeItem("mapPins");
  }
}

export default new MapPinsService();
