<template>
  <div class="sign-in w-100">
    <div
      class="
        container
        d-flex
        justify-content-center
        align-items-center
        body-container
      "
    >
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <img
            src="../assets/logo_v2.svg"
            alt="LandGlide"
            class="img-fluid mb-5"
            width="170"
          />
          <div class="body-content">
            <h1>Continue With Email</h1>
            <hr />
            <h4>
              Enter your email address for access to the most advanced parcel
              data and property line map application.
            </h4>
          </div>
        </div>

        <div class="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
          <div class="body-content">
            <div class="form-fields text-left mt-4 mb-4">
              <label for="email">Email</label>
              <input id="email" type="email" class="email-field" />
              <div class="error-message text-center mt-3" />
            </div>
            <p class="mt-5">
              <router-link to="/sign-in" class="cta-link back-link">
                <font-awesome-icon :icon="['fas', 'chevron-left']" /> Back to
                Sign In Options </router-link
              ><br />
              <button class="btn blue next-btn" @click="emailAuth">
                Continue<font-awesome-icon :icon="['fas', 'chevron-right']" />
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
export default {
  name: "SignInWithEmailLink",
  analytics: null,
  auth: null,
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
    this.$options.auth = getAuth(firebaseApp);
    logEvent(this.$options.analytics, "tapped_sign_in_with_email");
  },
  methods: {
    emailAuth() {
      const urlParts = window.location.href.split("/");
      const domain = urlParts[0] + "//" + urlParts[2];
      const email = document.getElementById("email").value;
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: domain + "/continue-with-email/sign-in",
        // This must be true.
        handleCodeInApp: true,
        iOS: {
          bundleId: "com.landglide.ios",
        },
        android: {
          packageName: "com.landglide.android",
          installApp: true,
          minimumVersion: "12",
        },
        dynamicLinkDomain: "s8x25.app.goo.gl",
      };
      sendSignInLinkToEmail(this.$options.auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          this.$router.push("/continue-with-email/confirm");
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          const errorCode = error.code;
          $("#email").addClass("error-field");
          $(".error-message").html(
            "<p>" + this.errorMessage(errorCode) + "</p>"
          );
        });
    },
    errorMessage(code) {
      if (code == "auth/invalid-user-token") {
        return (
          "Sorry but your credentials are no longer valid. Please sign in again.  - [" +
          code +
          "]"
        );
      } else if (code == "auth/invalid-tenant-id") {
        return (
          "Sorry but your credentials are not valid. Please contact Customer Service for assistance logging in.  - [" +
          code +
          "]"
        );
      } else if (code == "auth/user-disabled") {
        return (
          "Your account has been disabled. Please contact Customer Service for assistance logging in.  - [" +
          code +
          "]"
        );
      } else if (
        code == "auth/network-request-failed" ||
        code == "auth/too-many-requests"
      ) {
        return (
          "Something went wrong. Please try again later.  - [" + code + "]"
        );
      } else {
        return (
          "Something went wrong. Please contact Customer Service for assistance logging in. - [" +
          code +
          "]"
        );
      }
    },
  },
};
</script>

<style lang="scss"></style>
